import React, { Component } from 'react';
import "./Whatsapp.css";

class Whatsapp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            currentUserId: null,
            messages: [],
            newMessage: '',
            takeOver: false,
            searchQuery: '', // New state for search query
        };
    }

    componentDidMount() {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;
        const projectInfoUrl = `https://pako-ai.herokuapp.com/projectInfo?projectId=${projectId}`;

        fetch(projectInfoUrl)
            .then(response => response.json())
            .then(data => {
                // Assuming the project information contains a WhatsApp field
                const projectWhatsapp = data.whatsapp;
                this.setState({ projectWhatsapp });
            })
            .catch(error => {
                console.error('Error fetching project information:', error);
            });

        this.fetchMessages(); // Fetch messages when the component is mounted
        setInterval(this.fetchMessages, 10000); // Fetch messages every 30 seconds
        setInterval(this.fetchTakeoverStatus, 10000); // Call fetchTakeoverStatus every 10 seconds
    }


    getTakeOverStatus = (projectId, userId) => {
        // Make a POST request to the API endpoint
        return fetch('https://pako-ai.herokuapp.com/getTakeOverStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectId, userId }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch takeover status');
                }
                return response.json();
            })
            .then(data => {
                return data.status;
            })
            .catch(error => {
                console.error(error);
                return false; // Return false if the API call fails
            });
    };

    fetchMessages = () => {
        // Get the selected project ID from local storage
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;

        // Make an API call to get the list of messages for the selected project
        fetch(`https://pako-ai.herokuapp.com/messages?projectId=${projectId}`)
            .then(response => response.json())
            .then(data => {
                // Update the state with the new messages
                this.setState({ messages: data, projectId: projectId });

                // Filter the data by customerPhone and app_name
                const filteredData = data.filter(user => user.sender && user.sender.toLowerCase() !== 'Whatsappbot' &&
                    user.app_name.toLowerCase() === 'whatsapp');

                // Group the users by their id to get unique users
                const uniqueUsers = filteredData.reduce((acc, cur) => {
                    const existingUser = acc.find(user => user.id === cur.sender);
                    if (existingUser) {
                        return acc;
                    }
                    return [...acc, { id: cur.sender, name: cur.sender, date: cur.date }];
                }, []);
                // Sort the uniqueUsers array by date in descending order
                uniqueUsers.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Call getTakeOverStatus API for each user and update the state with the takeover status
                const userPromises = uniqueUsers.map(user => this.getTakeOverStatus(projectId, user.id));
                Promise.allSettled(userPromises)
                    .then(results => {
                        const updatedUsers = uniqueUsers.map((user, index) => ({
                            ...user,
                            takeOver: results[index].status === 'fulfilled' ? results[index].value : false
                        }));
                        this.setState({ users: updatedUsers });
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    };


    fetchTakeoverStatus = () => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;
        const { currentUserId } = this.state;

        const request = {
            projectId: projectId,
            userId: currentUserId
        };

        fetch('https://pako-ai.herokuapp.com/getTakeOverStatus', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    console.error(data.error);
                    // Handle error condition, e.g., show an error message
                } else {
                    const { status } = data;
                    // Update the state with the fetched takeover status
                    this.setState({ takeOver: status });
                }
            })
            .catch(error => {
                console.error(error);
                // Handle error condition, e.g., show an error message
            });
    };

    selectUser = (userId) => {
        this.setState(
            {
                currentUserId: userId
            },
            () => {
                // Call fetchTakeoverStatus after setting the currentUserId state
                this.fetchTakeoverStatus();
            }
        );
    };

    handleMessageChange = (event) => {
        this.setState({
            newMessage: event.target.value
        });
    };

    handleSendMessage = () => {
        const { messages, newMessage, currentUserId, projectId } = this.state;
        const newMessageObject = {
            id: messages.length + 1,
            sender: 'You',
            message: newMessage,
            date: new Date().toISOString()
        };
        const request = {
            Body: newMessageObject.message,
            to: currentUserId,
            projectId: projectId,
            From: this.state.projectWhatsapp
        };
        fetch('https://pako-ai.herokuapp.com/whatsapp/sendMessage', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    // Set the new state only if the API call was successful
                    this.setState({
                        messages: [...messages, newMessageObject],
                        newMessage: ''
                    });
                } else {
                    // Handle the error if the API call was not successful
                    throw new Error('Failed to send message');
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    handleSendTest = () => {
        const { messages, newMessage, currentUserId, projectId } = this.state;
        const newMessageObject = {
            id: messages.length + 1,
            sender: 'You',
            message: newMessage,
            date: new Date().toISOString()
        };
        const request = {
            Body: newMessageObject.message,
            to: currentUserId,
            projectId: projectId,
            From: this.state.projectWhatsapp
        };
        fetch('https://pako-ai.herokuapp.com/carsome/reminder/start', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    // Set the new state only if the API call was successful
                    this.setState({
                        messages: [...messages, newMessageObject],
                        newMessage: ''
                    });
                } else {
                    // Handle the error if the API call was not successful
                    throw new Error('Failed to send message');
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    handleTakeOver = () => {
        const { projectId, currentUserId } = this.state;

        const request = {
            projectId: projectId,
            userId: currentUserId,
        };
        fetch('https://pako-ai.herokuapp.com/takeover', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    // Set the new state only if the API call was successful
                    this.setState({
                        takeOver: true
                    });
                } else {
                    // Handle the error if the API call was not successful
                    throw new Error('Failed to send message');
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    handleLeaveOver = () => {
        const { projectId, currentUserId } = this.state;

        const request = {
            projectId: projectId,
            userId: currentUserId,
            channel: 'whatsapp',
            sender: this.state.projectWhatsapp
        };
        fetch('https://pako-ai.herokuapp.com/leave_over', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    // Set the new state only if the API call was successful
                    this.setState({
                        takeOver: false
                    });
                } else {
                    // Handle the error if the API call was not successful
                    throw new Error('Failed to send message');
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    handleSearch = (event) => {
        this.setState({ searchQuery: event.target.value });
    };
    renderUserList() {
        const { users, currentUserId, searchQuery } = this.state;

        // Filter users based on search query
        const filteredUsers = users.filter(user =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Get unique WhatsApp numbers
        const uniqueWhatsAppNumbers = Array.from(
            new Set(filteredUsers.map(user => user.id))
        ).filter(number => number.toLowerCase() !== 'chatbot');

        if (uniqueWhatsAppNumbers.length > 0) {
            // Call selectUser with last user in the list if currentUserId is not set
            if (!currentUserId) {
                this.selectUser(uniqueWhatsAppNumbers[uniqueWhatsAppNumbers.length - 1]);
            }

            return (
                <div className="user-list">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search number..."
                        value={searchQuery}
                        onChange={this.handleSearch}
                    />
                    {uniqueWhatsAppNumbers.map(number => {
                        const user = filteredUsers.find(u => u.id === number);
                        const isCurrentUser = currentUserId === user.id;
                        const shouldShowBadge = user.takeOver;

                        return (
                            <div
                                key={user.id}
                                className={`user-item ${isCurrentUser ? 'selected' : ''}`}
                                onClick={() => {
                                    if (!isCurrentUser) {
                                        this.selectUser(user.id);
                                    }
                                }}
                            >
                                {user.name}
                                {isCurrentUser && this.renderLastMessageTimestamp(user.id)}
                                {shouldShowBadge && <span className="badge">Human action</span>}
                            </div>
                        );
                    })}
                </div>
            );
        }

        // Render the search input even when there are no results
        return (
            <div className="user-list">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search user..."
                    value={searchQuery}
                    onChange={this.handleSearch}
                />
                <div>No Whatsapps found</div>
            </div>
        );
    }


    renderLastMessageTimestamp(userId) {
        return null;
    }

    renderMessages() {
        const { messages, currentUserId } = this.state;
        const userMessages = messages.filter(
            message =>
                message.sender === 'You' ||
                message.sender === currentUserId ||
                message.receiver === currentUserId
        );
        const lastFetchedMessage = messages[messages.length - 1];
        return userMessages.map(message => {
            const isSent = message.sender.toLowerCase() === 'you' || message.sender.toLowerCase() === this.state.projectWhatsapp;
            const isSentByAI = message.sender.toLowerCase() === 'whatsappbot' || message.sender.toLowerCase() === 'chatbot' ;
            const senderName = isSent ? 'You' : (isSentByAI ? 'AI' : message.sender);

            const serverMessageTime = new Date(message.date);
            const localMessageTime = new Date(serverMessageTime.getTime() - (new Date().getTimezoneOffset() * 60000));
            const currentTime = new Date();
            const timeDiffInMinutes = Math.floor((currentTime - localMessageTime) / 60000);

            let timeDiffString = '';
            if (timeDiffInMinutes < 60) {
                timeDiffString = `${timeDiffInMinutes} minutes ago`;
            } else if (timeDiffInMinutes < 1440) {
                const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
                timeDiffString = `${timeDiffInHours} hours ago`;
            } else {
                const timeDiffInDays = Math.floor(timeDiffInMinutes / 1440);
                timeDiffString = `${timeDiffInDays} days ago`;
            }
            return (
                <div
                    key={message.id}
                    className={`message-item ${isSent || isSentByAI ? 'sent' : 'received'}`}
                >
                    <div className="message-sender">{senderName}</div>
                    <div className="message-content">{message.message}</div>
                    <div className="message-timestamp">
                        {lastFetchedMessage && message.id === lastFetchedMessage.id
                            ? ''
                            : ''}
                        {timeDiffString}
                    </div>
                </div>
            );
        });
    }


    render() {
        const { currentUserId, newMessage, takeOver } = this.state;
        const currentUser = this.state.users.find((user) => user.id === currentUserId);

        return (
            <div className="Whatsapp-container">
                {this.renderUserList()}
                <div className="Whatsapp-interface">
                    {currentUserId && (
                        <>
                            <div className="Whatsapp-header">{currentUser.name}</div>
                            <div className="messages-container">{this.renderMessages()}</div>
                            <div className="new-message">
                                <input
                                    disabled={!takeOver}
                                    type="text"
                                    placeholder="Type a message..."
                                    value={newMessage}
                                    onChange={this.handleMessageChange}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            this.handleSendMessage();
                                        }
                                    }}
                                />
                                {takeOver && (
                                    <button onClick={this.handleSendMessage}>Send</button>
                                )}
                                {/*<button onClick={this.handleSendTest}>Start test</button>*/}
                                {!takeOver && (
                                    <button onClick={this.handleTakeOver}>Take over</button>
                                )}
                                {takeOver && (
                                    <button onClick={this.handleLeaveOver}>Leave control to AI</button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

}

export default Whatsapp;
