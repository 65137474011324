import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const CarsomeUploader = ({ extraVariables, setExtraVariables, onUploadComplete }) => {
    const [fields, setFields] = useState([]);

    const updateField = (index, key, value) => {
        const newFields = [...fields];
        newFields[index][key] = value;
        setFields(newFields);
    };

    const addField = () => {
        setFields([...fields, { fieldName: '', value: '' }]);
    };

    const toCamelCase = (str) => {
        if (!str) return 'undefinedKey';  // handle undefined headers

        return str.trim()
            .toLowerCase()
            .split(/\s+/)  // splits by whitespace
            .map((word, index) => {
                if (index === 0) return word;
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join('');
    };
    const handleUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Directly read the first sheet, assuming there's at least one sheet
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const headers = json[0].map(toCamelCase);
            const rows = json.slice(1);  // Grabbing all rows after headers

            // Convert rows to the desired format
            const formattedRows = rows.map(row => {
                return row.reduce((acc, cell, index) => {
                    acc[headers[index]] = cell;
                    return acc;
                }, {});
            });

            // Assuming you want the fields state to be set with the first row data
            const firstRowFields = formattedRows[0];
            const flattenedFields = [];
            for (let key in firstRowFields) {
                flattenedFields.push({
                    fieldName: key,
                    value: firstRowFields[key]
                });
            }

            setFields(flattenedFields); // Using the first row to populate the fields for the table

            // Callback to pass the formattedRows data back to the parent
            onUploadComplete(formattedRows);
        };

        reader.readAsArrayBuffer(file);
    };







    return (
        <div>
            <input type="file" accept=".xlsx, .xls" onChange={handleUpload} />
            <table>
                <thead>
                <tr>
                    <th>Field Name</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {fields.map((field, index) => (
                    <tr key={index}>
                        <td>
                            <input
                                value={field.fieldName}
                                onChange={e => updateField(index, 'fieldName', e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                value={field.value}
                                onChange={e => updateField(index, 'value', e.target.value)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button onClick={addField}>Add New Field</button>

        </div>
    );
};

export default CarsomeUploader;
