import React, { useState, useEffect } from "react";
import ModelSelector from "./ModelSelector";
import "./Upload.css";
import UseCases from "./UseCases";
import FlowUploader from "./FlowUploader"

function UploadForm() {
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState(false);
    const [showFiles, setShowFiles] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [deleteFile, setDeleteFile] = useState(null);
    const [activeTab, setActiveTab] = useState('model');

    useEffect(() => {
        const storedProject = localStorage.getItem("selectedProject");
        if (storedProject) {
            setSelectedProject(JSON.parse(storedProject));
        }
    }, []);

    const handleShowUpload = () => {
        setShowFiles(!showFiles);
    }

    const renderActiveTabContent = () => {
        switch (activeTab) {
            case 'model':
                return (
                    <div>
                        <div className="main-upload">
                            We have curated a selection of AI models for you.
                            <h3>
                                Feel free to choose one that suits your needs.
                            </h3>
                            <ModelSelector projectId={selectedProject?.id} />
                            <br/>

                            <h3>
                                Upload your context file (doc format), and our AI will be trained on
                                this data instantly!
                            </h3>
                            <br />
                            {files && files.length > 0 ? (
                                <div>
                                    <h3>The FAQ knowledge is coming from:</h3>
                                    <ul>
                                        {files.map((file, index) => (
                                            <li className="no-menu" key={index}>
                                                {file.filename}. {file.created_at}. <br />
                                                <a    className="download-link"
                                                    href={`https://pako-ai.herokuapp.com/download?filename=${file.filename}&projectId=${file.projectId}&fileType=original`}
                                                >
                                                    <button className="empty-button">Download</button>
                                                </a>
                                                {/* Move this button outside of the outer button */}
                                                <button
                                                    className="empty-button delete-button"
                                                    onClick={() => handleDelete(file)}
                                                >
                                                    Delete Knowledge
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div>
                                    <p>You have not uploaded any files. Do you want to upload now?</p>
                                    <button className="wizard-button" onClick={handleShowUpload}>
                                        {showFiles ? "Close" : "Upload"}
                                    </button>
                                </div>
                            )}

                            {fileLoading && <div>Loading...</div>}

                            {deleteFile && (
                                <div className="delete-popup">
                                    <h3>Are you sure you want to delete {deleteFile.filename}?</h3>
                                    <button onClick={handleConfirmDelete}>Yes</button>
                                    <button onClick={() => setDeleteFile(null)}>No</button>
                                </div>
                            )}

                            {showFiles && (
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="file"
                                        accept=".pdf,.doc,.docx"
                                        onChange={handleFileChange}
                                    />
                                    <button type="submit">Upload Context</button>
                                </form>
                            )}
                        </div>
                        <FlowUploader></FlowUploader>
                    </div>
                );
            case 'use-cases':
                return <div class="main-upload use-cases"><UseCases /></div>;
            default:
                return null;
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!file || (!file.type.includes("pdf") && !file.type.includes("word"))) {
            alert("Please select a PDF or DOC file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectId", selectedProject.id);

        setFileLoading(true);
        fetch("https://pako-ai.herokuapp.com/upload", {
            method: "POST",
            body: formData,
        })
            .then(() => {
                setFileLoading(false);
                fetch(
                    `https://pako-ai.herokuapp.com/files?projectId=${selectedProject.id}&fileType=original`
                )
                    .then((response) => response.json())
                    .then((data) => {
                        setFiles(data);
                    });
            });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDelete = (file) => {
        setDeleteFile(file);
    };

    const handleConfirmDelete = () => {
        fetch(
            `https://pako-ai.herokuapp.com/files/${deleteFile.filename}?projectId=${selectedProject.id}&fileType=original`,
            { method: "DELETE" }
        ).then(() => {
            setDeleteFile(null);
            setFiles(files.filter((file) => file.filename !== deleteFile.filename));
        });
    };

    useEffect(() => {
        if (selectedProject) {
            fetch(
                `https://pako-ai.herokuapp.com/files?projectId=${selectedProject.id}&fileType=original`
            )
                .then((response) => response.json())
                .then((data) => setFiles(data));
        }
    }, [selectedProject]);

    return (
        <div>
            {/* Tab Navigation */}
            <div className="tab-container">
                <button className={activeTab === 'model' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('model')}>
                    Model
                </button>
                <button className={activeTab === 'use-cases' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('use-cases')}>
                   Use Cases
                </button>
            </div>
            {/* Render the active tab content */}
            {renderActiveTabContent()}
        </div>
    );
}

export default UploadForm;
