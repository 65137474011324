import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Tab, Nav } from 'react-bootstrap';
import ScheduledReminders from './ScheduledReminders';
import './Reminders.css'; // Import the CSS file

const Reminders = () => {
    const [rows, setRows] = useState([{ message: '', number: '', time: null }]);
    const [errors, setErrors] = useState([]);
    const [projectId, setProjectId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
    const [showSuccessLabel, setShowSuccessLabel] = useState(false); // State to show success label

    useEffect(() => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const parsedProjectId = selectedProjectStorage
            ? JSON.parse(selectedProjectStorage).id
            : null;
        setProjectId(parsedProjectId);
    }, []);

    const handleAddRow = () => {
        const prevRow = rows[rows.length - 1];

        // Check if the previous row has empty fields
        if (!prevRow.message || !prevRow.number || !prevRow.time) {
            setErrors([rows.length - 1]);
            return;
        }

        const newRow = { message: '', number: '', time: null };
        setRows([...rows, newRow]);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);

        // Remove error if the field is filled
        if (value && errors.includes(index)) {
            const updatedErrors = errors.filter((errorIndex) => errorIndex !== index);
            setErrors(updatedErrors);
        }
    };

    const handleDateChange = (index, date) => {
        const updatedRows = [...rows];
        updatedRows[index].time = date;
        setRows(updatedRows);

        // Remove error if the field is filled
        if (date && errors.includes(index)) {
            const updatedErrors = errors.filter((errorIndex) => errorIndex !== index);
            setErrors(updatedErrors);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = [];

        // Check for empty or missing fields
        rows.forEach((row, index) => {
            if (!row.message || !row.number || !row.time) {
                validationErrors.push(index);
            }
        });

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        }

        // Prevent double-clicking on the button
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        try {
            const selectedProjectStorage = localStorage.getItem('selectedProject');
            const projectId = selectedProjectStorage
                ? JSON.parse(selectedProjectStorage).id
                : null;

            const requestData = rows.map((row) => ({
                message: row.message,
                recipient: row.number,
                time: row.time.toISOString(), // Convert to ISO 8601 format
                projectId: projectId,
            }));

            const response = await axios.post(
                'https://pako-ai.herokuapp.com/scheduleMessages',
                requestData
            );
            console.log(response.data); // Process response data as needed

            // Reset form after successful submission
            setRows([{ message: '', number: '', time: null }]);
            setErrors([]);
            setShowSuccessLabel(true); // Show success label

            // Hide success label after 3 seconds
            setTimeout(() => {
                setShowSuccessLabel(false);
            }, 3000);
        } catch (error) {
            if (error.response && error.response.data) {
                const { message } = error.response.data;
                if (message.includes('SendAt time must be between 900 seconds and 7 days')) {
                    // Display user-friendly message for specific error
                    setErrors([
                        {
                            index: 0,
                            message: 'Message time must be between 900 seconds and 7 days in the future',
                        },
                    ]);
                } else {
                    console.error(error);
                }
            } else {
                console.error(error);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="reminders-container generic-box margin-10">
            <h3>Schedule Reminders</h3>
            <Tab.Container defaultActiveKey="scheduled">
                <Nav variant="tabs" className="custom-tabs">
                    <Nav.Item className="flex-grow-1">
                        <Nav.Link eventKey="scheduled">Scheduled</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="flex-grow-1">
                        <Nav.Link eventKey="schedule-more">Schedule More</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="scheduled">
                        <ScheduledReminders projectId={projectId} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="schedule-more">
                        <form onSubmit={handleSubmit}>
                            {rows.map((row, index) => (
                                <div className="row-container" key={index}>
                                    <div className="message-container">
                    <textarea
                        className="message-input"
                        name="message"
                        value={row.message}
                        placeholder="Message"
                        onChange={(event) => handleInputChange(index, event)}
                    />
                                        {errors.includes(index) && (
                                            <span className="required-label">Required</span>
                                        )}
                                    </div>
                                    <div className="input-container">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                name="number"
                                                value={row.number}
                                                placeholder="Whatsapp number"
                                                onChange={(event) => handleInputChange(index, event)}
                                            />
                                            <DatePicker
                                                selected={row.time}
                                                onChange={(date) => handleDateChange(index, date)}
                                                className="date-input"
                                                showTimeSelect
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                placeholderText="Select date and time"
                                            />
                                        </div>
                                        {errors.includes(index) && (
                                            <span className="error-label">
                        Please provide a date at least 60 minutes from now
                      </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {errors.length > 0 && (
                                <div className="error-message">
                                    {errors.map((error, index) => (
                                        <span key={index}>{error.message}</span>
                                    ))}
                                </div>
                            )}
                            {showSuccessLabel && <div className="success-label">Messages scheduled!</div>}
                            <div className="button-container">
                                <button
                                    className="wizard-button add-customer-button"
                                    type="button"
                                    onClick={handleAddRow}
                                >
                                    Add Customer
                                </button>
                                <button className="wizard-button" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Schedule Reminders'}
                                </button>
                            </div>
                        </form>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
};

export default Reminders;
