import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ScheduledReminders.css';

const ScheduledReminders = ({ projectId }) => {
    const [messages, setMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [searchNumber, setSearchNumber] = useState('');
    const [sortOrder, setSortOrder] = useState('recent');
    const [statusFilter, setStatusFilter] = useState('all');

    useEffect(() => {
        const fetchScheduledReminders = async () => {
            try {
                const response = await axios.get(
                    `https://pako-ai.herokuapp.com/scheduledMessages/${projectId}`
                );
                const { messages } = response.data;

                // Fetch the status for each message
                const updatedMessages = await Promise.all(
                    messages.map(async (message) => {
                        try {
                            const messageStatusResponse = await axios.get(
                                `https://pako-ai.herokuapp.com/getMessageStatus/${message.service_id}`
                            );
                            const { status } = messageStatusResponse.data.message_info;

                            // Add the status to the message object
                            return {
                                ...message,
                                message_status: status,
                            };
                        } catch (error) {
                            console.error(error);
                            // If there's an error, add 'Unknown' status to the message object
                            return {
                                ...message,
                                message_status: 'Unknown',
                            };
                        }
                    })
                );

                setMessages(updatedMessages);
            } catch (error) {
                console.error(error);
            }
        };

        fetchScheduledReminders();
    }, [projectId]);

    useEffect(() => {
        let filtered = messages;

        if (selectedDate) {
            filtered = filtered.filter((message) =>
                message.send_time.includes(selectedDate)
            );
        }

        if (searchNumber) {
            const searchRegex = new RegExp(searchNumber, 'i');
            filtered = filtered.filter((message) =>
                searchRegex.test(message.recipient)
            );
        }

        if (statusFilter !== 'all') {
            filtered = filtered.filter(
                (message) => message.message_status === statusFilter
            );
        }

        filtered.sort((a, b) => {
            const dateA = new Date(a.send_time);
            const dateB = new Date(b.send_time);
            return sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
        });

        setFilteredMessages(filtered);
    }, [selectedDate, searchNumber, messages, sortOrder, statusFilter]);

    const handleDateFilter = (e) => {
        const { value } = e.target;
        setSelectedDate(value);
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchNumber(value);
    };

    const handleSortOrder = (e) => {
        const { value } = e.target;
        setSortOrder(value);
    };

    const handleStatusFilter = (e) => {
        const { value } = e.target;
        setStatusFilter(value);
    };

    return (
        <div className="scheduled-reminders">
            <h3>Scheduled Messages</h3>
            <div className="filter-section">
                <label>Date Filter:</label>
                <input
                    type="date"
                    className="filter-input"
                    value={selectedDate}
                    onChange={handleDateFilter}
                />
                <label>Search by Phone Number:</label>
                <input
                    type="text"
                    className="filter-input"
                    value={searchNumber}
                    onChange={handleSearch}
                    placeholder="Enter phone number"
                />
                <label>Sort by Date:</label>
                <select
                    className="sort-select"
                    value={sortOrder}
                    onChange={handleSortOrder}
                >
                    <option value="recent">Most Recent</option>
                    <option value="oldest">Oldest</option>
                </select>
                <label>Filter by Status:</label>
                <select
                    className="status-filter-select"
                    value={statusFilter}
                    onChange={handleStatusFilter}
                >
                    <option value="all">All</option>
                    <option value="sent">Sent</option>
                    <option value="failed">Failed</option>
                    <option value="scheduled">Scheduled</option>
                </select>
            </div>
            {/* Display the filtered and sorted messages */}
            {filteredMessages.length > 0 ? (
                filteredMessages.map((message, index) => (
                    <div key={index} className="message-container">
                        <p>Message: {message.message}</p>
                        <p>Recipient: {message.recipient}</p>
                        <p>Send Time: {message.send_time}</p>
                        <p>Status: {message.message_status}</p>
                    </div>
                ))
            ) : (
                <p className="no-messages">No messages found.</p>
            )}
        </div>
    );
};

export default ScheduledReminders;
