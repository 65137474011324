import React, { Component } from 'react';
import './Batches.css';
import { Form, Tooltip, OverlayTrigger, DropdownButton, Dropdown  } from 'react-bootstrap';
import { BiInfoCircle } from 'react-icons/bi'; // Import the icon you want to use
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Batches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUseCase: "Case 1",
            selectedPhoneNumber: "",
            selectedDate: null,
            phoneNumbers: [],
            selectedTone: "narrative",
            selectedLanguage: "en",
            selectedTemperature: "1",
            callsInProgress: false,
            callsEnded: false,
            fakeTableData: [],
            models: [], // Array to store the available models
            selectedModel: "", // Variable to store the currently selected model
        };
        // Bind the handleUploadFile function to the component instance
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    componentDidMount() {
        // Fetch the stored project from localStorage
        const storedProject = localStorage.getItem("selectedProject");

        if (storedProject) {
            // Initialize an AbortController to handle fetch cancellation
            const controller = new AbortController();
            const signal = controller.signal;

            // Save the controller in the component instance
            this.fetchController = controller;

            fetch(`https://pako-ai.herokuapp.com/getAllModels`, { signal })
                .then(response => response.json())
                .then(data => {
                    // Assuming the response contains the models as an array
                    debugger;
                    const modelsFromAPI = data; // Replace 'models' with the actual key in the API response

                    this.setState({ models: modelsFromAPI });

                    // Set the selected model to the first model in the list
                    if (modelsFromAPI.length > 0) {
                        this.setState({ selectedModel: modelsFromAPI[0].name });
                    }
                })
                .catch(error => {
                    // Handle the error if the API call fails
                    console.error('Error fetching models:', error);
                });
        }
    }
    handleUploadFile = (event) => {
        debugger;
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const contents = e.target.result;
                const phoneNumbers = this.parsePhoneNumbers(contents);
                debugger;
                this.setState({ phoneNumbers });
            };

            reader.readAsText(file);
        }
    };

    // Helper function to parse phone numbers from the uploaded file
    parsePhoneNumbers = (contents) => {
        // Remove the double quotes around each number
        contents = contents.replace(/"/g, '');

        // Split the contents by newline characters (\n) to get an array of lines
        const lines = contents.split('\n');

        // // Filter and return only lines that are valid phone numbers
        // const phoneNumbers = lines.filter((line) => {
        //     const trimmedLine = line.trim();
        //     return trimmedLine.length === 10 && !isNaN(trimmedLine);
        // });

        return lines;
    };


    handleUseCaseChange = (event) => {
        this.setState({ selectedUseCase: event.target.value });
    };

    handleModelChange = (selectedModel) => {
        this.setState({ selectedModel });
    };

    handleStartCalls = () => {
        // Set callsInProgress to true when the button is clicked
        this.setState({ callsInProgress: true }, () => {
            const { phoneNumbers } = this.state;
            const maxRows = phoneNumbers.length;
            let currentRow = 0;

            // Function to add the next row to the table
            const addNextRow = () => {
                if (currentRow < maxRows) {
                    const phoneNumber = phoneNumbers[currentRow];
                    const rowData = {
                        phoneNumber,
                        duration: this.generateRandomDuration(),
                        appointmentConfirmed: this.generateRandomConfirmation(),
                    };

                    // Update fakeTableData in the state
                    this.setState((prevState) => ({
                        fakeTableData: [...prevState.fakeTableData, rowData],
                    }));

                    currentRow++;

                    // Schedule the next row addition after a random delay
                    setTimeout(addNextRow, Math.random() * 3000); // Change 3000 to adjust the delay (in milliseconds) between each row
                } else {
                    // All rows added, set callsInProgress to false and callsEnded to true after a delay
                    setTimeout(() => {
                        this.setState({
                            callsInProgress: false,
                            callsEnded: true,
                        });
                    }, 1000); // You can adjust the delay before setting callsEnded to true (in milliseconds)
                }
            };

            // Start adding rows to the table
            addNextRow();
        });
    }

    handlePhoneNumberChange = (event) => {
        const { callsEnded } = this.state;

        // If calls have already started, do not update phoneNumbers state
        if (callsEnded) {
            return;
        }

        const selectedNumbers = [...event.target.options]
            .filter((option) => option.selected)
            .map((option) => option.value);

        this.setState((prevState) => ({
            phoneNumbers: [
                ...prevState.phoneNumbers,
                ...selectedNumbers.filter(
                    (number) => !prevState.phoneNumbers.includes(number)
                ),
            ],
        }));
    };



    handleRemovePhoneNumber = (number) => {
        this.setState((prevState) => ({
            phoneNumbers: prevState.phoneNumbers.filter((num) => num !== number),
        }));
    };

    handleToneChange = (event) => {
        this.setState({ selectedTone: event.target.value });
    };

    handleLanguageChange = (event) => {
        this.setState({ selectedLanguage: event.target.value });
    };

    handleTemperatureChange = (event) => {
        this.setState({ selectedTemperature: event.target.value });
    };
    generateRandomConfirmation = () => {
        return Math.random() < 0.5;
    };

    // Function to generate a fake call table with random data
    generateFakeTable = () => {
        const { phoneNumbers } = this.state;
        const tableData = phoneNumbers.map((phoneNumber) => ({
            phoneNumber,
            duration: this.generateRandomDuration(),
            appointmentConfirmed: this.generateRandomConfirmation(),
            dateOfCall: this.generateRandomDate(),
            customerName: this.generateRandomName()
        }));

        // Set the showCallAgainButton property based on the appointmentConfirmed value
        tableData.forEach((rowData) => {
            rowData.showCallAgainButton = rowData.appointmentConfirmed <= 0.5;
        });

        return tableData;
    };

    // Function to simulate a random call duration between 10 and 120 seconds
    generateRandomDuration = () => {
        return Math.floor(Math.random() * (120 - 10 + 1) + 10);
    };
    generateRandomDate = () => {
        const now = new Date(); // Current date and time
        const start = new Date(now.getTime() - 10 * 60 * 1000); // 10 minutes ago
        const end = now; // Current date and time

        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    };

    // Function to generate a random customer name
    generateRandomName = () => {
        const names = ["John Doe", "Jane Smith", "Michael Johnson", "Emily Williams", "Robert Brown"];
        return names[Math.floor(Math.random() * names.length)];
    };
    handleDateChange = (date) => {
        this.setState({ selectedDate: date });
    };
    render() {
        const { selectedUseCase, selectedDate, phoneNumbers, selectedTone, selectedLanguage, selectedTemperature, models, selectedModel } = this.state;

        const useCaseTooltips = {
            "Case 1": "This use case is for appointment reminders.",
            "Case 2": "This use case is for sales calls.",
        };

        // Extract callsInProgress and callsEnded variables from the state
        const { callsInProgress, callsEnded } = this.state;

        // Generate the fake table data
        const fakeTableData = this.generateFakeTable();

        return (
            <div className="main-batches">
                {!callsEnded && ( // Render the button section only when calls have not started
                    <div class="start-call">
                        Build your very own AI Agent Co-pilot that can call customers for various use cases 24/7. You can customise its tone, language, accent, reasoning and so much more!
                <h3>Start now!</h3>
                <Form.Label>Pick a use case:</Form.Label>
                <div className="picker-dropdown">
                    <select value={selectedUseCase} onChange={this.handleUseCaseChange} className="dropdown-toggle btn btn-light">
                        <option value="Case 1">Case 1 ( Appointment reminder ) </option>
                        <option value="Case 2">Case 2 ( Sales Call )</option>
                    </select>
                    {/* Render the icon for the tooltip */}
                    <OverlayTrigger
                        key="useCaseTooltip"
                        placement="right"
                        overlay={
                            <Tooltip id="useCaseTooltip">
                                {useCaseTooltips[selectedUseCase]}
                            </Tooltip>
                        }
                    >
                        <span className="info-icon">
                            <BiInfoCircle />
                        </span>
                    </OverlayTrigger>
                </div>


                <Form.Label>Phone numbers:</Form.Label>
                <div className="phone-number-input">
                    <div className="phone-number-input">
                        <label htmlFor="uploadFile" className="wizard-button upload-button">
                            Upload from file
                            <input
                                id="uploadFile"
                                type="file"
                                accept=".txt"
                                style={{ display: 'none' }}
                                onChange={this.handleUploadFile}
                            />
                        </label>
                    </div>
                    <div className="phone-number-pills">
                        {phoneNumbers.map((number, index) => (
                            <div key={index} className="phone-number-pill">
                                {number}
                                <span
                                    className="remove-pill"
                                    onClick={() => this.handleRemovePhoneNumber(number)}
                                >
                &times;
              </span>
                            </div>
                        ))}
                    </div>
                </div>

                        {/* Add the same class "option-dropdown" for the date picker */}
                        <div className="option-dropdown">
                            <Form.Label>Date:</Form.Label>
                            <div className="date-picker">
                                <DatePicker
                                    className="date-input"
                                    selected={selectedDate}
                                    onChange={this.handleDateChange} // Use the new handler method here
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                />
                            </div>
                        </div>

                <h4>AI Options</h4>
                        <Form.Label>Model:</Form.Label>

                        <DropdownButton id="model-dropdown-button" title={selectedModel || 'Select Model'} variant="light">
                            {models.map((model, index) => (
                                <Dropdown.Item key={index} onClick={() => this.handleModelChange(model)}>
                                    {model.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>


                        <div className="options-dropdowns">
                    <div className="option-dropdown">
                        <Form.Label>Tones:</Form.Label>
                        <select id="tones" value={selectedTone} onChange={this.handleToneChange} className="dropdown-toggle btn btn-light">
                            <option value="narrative">Narrative</option>
                            <option value="conversation">Conversation</option>
                            <option value="sales">Sales</option>
                        </select>
                    </div>

                    <div className="option-dropdown">
                        <label htmlFor="language">Language:</label>
                        <select id="language" value={selectedLanguage} onChange={this.handleLanguageChange} className="dropdown-toggle btn btn-light">
                            <option value="en">English</option>
                            <option value="british-accent">British Accent</option>
                            <option value="en-indian">English Indian</option>
                            <option value="en-philippines">English Philippines</option>
                        </select>
                    </div>

                    <div className="option-dropdown">
                        <label htmlFor="temperature">Temperature:</label>
                        <select id="temperature" value={selectedTemperature} onChange={this.handleTemperatureChange} className="dropdown-toggle btn btn-light">
                            <option value="0.5">0.5</option>
                            <option value="1">1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                {/* Add the buttons with the wizard-button class */}
                <div className="button-container">
                    <button className={`wizard-button ${callsInProgress || callsEnded ? 'disabled' : ''}`} onClick={this.handleStartCalls} disabled={callsInProgress || callsEnded}>
                        {callsInProgress
                            ? "Making Call/s..."
                            : callsEnded
                                ? "Calls ended"
                                : "Start call/s"}
                    </button>
                </div>
            </div>
                )}
                {/* Fake Call Table */}
                {callsEnded && ( // Render the table only when calls have started
                    <div className="call-table">
                        <h4>Calls completed!</h4>
                        <table>
                            <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>Duration (s)</th>
                                <th>Appointment Confirmed</th>
                                <th>Date of the Call</th> {/* New column */}
                                <th>Name of the Customer</th> {/* New column */}
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {fakeTableData.map((rowData, index) => (
                                <tr key={index}>
                                    <td>{rowData.phoneNumber}</td>
                                    <td>{rowData.duration}</td>
                                    <td>{rowData.appointmentConfirmed ? "Yes" : "No"}</td>
                                    <td>{rowData.dateOfCall.toLocaleString()}</td> {/* Display formatted date */}
                                    <td>{rowData.customerName}</td> {/* Display customer name */}

                                    <td>
                                        {rowData.showCallAgainButton && (
                                            // Show the button only when showCallAgainButton is true
                                            <button class="wizard-button" onClick={() => this.handleCallAgain(rowData.phoneNumber)}>
                                                Call Again
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}

export default Batches;
