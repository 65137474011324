import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Intentions.css';

const Intentions = () => {
    const [intentions, setIntentions] = useState([]);
    const [newIntention, setNewIntention] = useState('');

    useEffect(() => {
        fetchIntentions();
    }, []);

    const fetchIntentions = async () => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;

        try {
            const response = await axios.get('https://pako-ai.herokuapp.com/getProjectIntentions', {
                params: { projectId: projectId || 'ALL' }, // projectId is passed as a query parameter
            });
            setIntentions(response.data);
        } catch (error) {
            console.error('Error fetching intentions:', error);
        }
    };

    const handleNewIntentionChange = (event) => {
        setNewIntention(event.target.value);
    };

    const handleAddIntention = async () => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;

        try {
            const intentionData = {
                name: newIntention,
                projectId,
            };
            const addIntentionResponse = await axios.post('https://pako-ai.herokuapp.com/addIntention', intentionData);
            console.log('Added intention:', addIntentionResponse.data);
            fetchIntentions();
            setNewIntention('');
        } catch (error) {
            console.error('Error adding intention:', error);
        }
    };

    return (
        <div>
            <h2>Intentions</h2>
            <ul>
                {intentions.map((intention) => (
                    <li key={intention.id}>{intention.name}</li>
                ))}
            </ul>
            <div>
                <h3>Add New Intention</h3>
                <input type="text" value={newIntention} onChange={handleNewIntentionChange} />
                <button onClick={handleAddIntention}>Add Intention</button>
            </div>
        </div>
    );
};

export default Intentions;
