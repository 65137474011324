import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const ConversionsStats = () => {
    const [automatedPercentage, setAutomatedPercentage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        // Get the projectId from localStorage
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;

        if (projectId) {
            // Fetch data from the API endpoint with the projectId
            fetch(`https://pako-ai.herokuapp.com/loop?projectId=${projectId}`)
                .then((response) => response.json())
                .then((data) => {
                    // Filter responses that have the "intention" field
                    const filteredData = data.filter((item) => item.automated !== undefined);

                    // Aggregate data by date and count the number of automated responses for each date
                    const aggregatedData = filteredData.reduce((acc, curr) => {
                        const date = curr.date.split(' ')[0];
                        const automatedCount = curr.automated ? 1 : 0;

                        if (acc[date]) {
                            acc[date].count += 1;
                            acc[date].automatedCount += automatedCount;
                        } else {
                            acc[date] = {
                                date,
                                count: 1,
                                automatedCount,
                            };
                        }

                        return acc;
                    }, {});

                    // Convert aggregatedData object to an array
                    const chartDataArray = Object.values(aggregatedData);

                    // Calculate the percentage for each date
                    chartDataArray.forEach((data) => {
                        data.percentage = (data.automatedCount / data.count) * 100;
                    });

                    // Calculate the overall automated percentage
                    const totalAutomatedCount = chartDataArray.reduce((sum, data) => sum + data.automatedCount, 0);
                    const totalResponseCount = chartDataArray.reduce((sum, data) => sum + data.count, 0);
                    const automatedPercentage = (totalAutomatedCount / totalResponseCount) * 100;
                    setAutomatedPercentage(automatedPercentage);

                    setIsLoading(false); // Set isLoading to false after data is loaded
                    setChartData(chartDataArray); // Set chart data with aggregated data
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false); // Set isLoading to false in case of an error
                });
        } else {
            setIsLoading(false); // Set isLoading to false if projectId is null
        }
    }, []);

    return (
        <div style={{
            width: '99%',
            height: '400px', // Set a fixed height for the box
            margin: '0 auto',
            marginTop: '20px',
            marginRight: '20px',
            border: '1px solid #dedede',
            borderRadius: '10px', // Add this property for rounder borders
            padding: '10px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
        }}>
            {/* Render the box with the "Fully Automated Conversations" heading */}
            <h4><strong>Fully Automated Conversations</strong></h4>

            {/* Conditionally render the chart and percentage based on whether the data has been loaded */}
            {isLoading ? (
                // Show the box while the data is loading
                null
            ) : (
                <React.Fragment>
                    {automatedPercentage !== null ? (
                        // Show the percentage and the LineChart if data is available
                        <React.Fragment>
                            <h5><strong>Total fully managed: {automatedPercentage.toFixed(2)}%</strong></h5>
                            <ResponsiveContainer width="100%" height={290}>
                                <LineChart data={chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="percentage" stroke="#00ff00" />
                                </LineChart>
                            </ResponsiveContainer>
                        </React.Fragment>
                    ) : (
                        // Show "No available data yet" if data is not available
                        <p>No available data yet.</p>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default ConversionsStats;
