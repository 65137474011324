import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

class QueriesChart extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedProject: null, // add selectedProject to component's state
        };
    }

    setSelectedProject = (obj) => {
        this.setState({ selectedProject: obj }); // set selectedProject in component's state
    };

    async componentDidMount() {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const obj = JSON.parse(selectedProjectStorage);
            this.setSelectedProject(obj);
            const response = await fetch(`https://pako-ai.herokuapp.com/loop?projectId=${obj.id}`); // access selectedProject from component's state
            const result = await response.json();
            this.setState({ data: result });
        }
    }

    render() {
        const { data } = this.state;
        const filteredData = data.filter((item) => item.date);
        const chartData = filteredData.reduce(
            (acc, item) => {
                const date = new Date(item.date);
                const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
                const score = item.score || 0;
                if (acc[formattedDate]) {
                    acc[formattedDate].total++;
                    acc[formattedDate].sum += score;
                    acc[formattedDate].average = acc[formattedDate].sum / acc[formattedDate].total;
                } else {
                    acc[formattedDate] = {
                        name: formattedDate,
                        total: 1,
                        sum: score,
                        average: score,
                        date: date.getTime() // store the full date as timestamp for sorting
                    };
                }
                return acc;
            },
            {}
        );

        const chartDataArray = Object.values(chartData).sort((a, b) => a.date - b.date);

        return (
            <div>
                <div style={{
                    width: '100%',
                    margin: '0 auto',
                    marginTop: '20px',
                    marginRight: '20px',
                    minHeight: '400px',
                    border: '1px solid #dedede',
                    borderRadius: '10px', // Add this property for rounder borders
                    padding: '10px',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                }}>
                    {/*<span className="section-title">Total customer queries</span>*/}
                    <h4><strong>Total customer queries</strong></h4><br/>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={chartDataArray} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <XAxis dataKey="name" tick={{fontSize: 12}} />
                            <YAxis dataKey="total" axisLine={false} />
                            <Line type="monotone" dataKey="total" stroke="#8884d8" />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
}

export default QueriesChart;
