import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, DropdownButton, Tab, Tabs } from 'react-bootstrap';
import "./Admin.css";

function Admin() {
    const [projects, setProjects] = useState([]);
    const [newProjectName, setNewProjectName] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [admins, setAdmins] = useState([]);
    const [userId, setUserId] = useState('');
    const [models, setModels] = useState([]);
    const [newModelName, setNewModelName] = useState('');

    useEffect(() => {
        const jsonUser = localStorage.getItem('user');
        let obj = JSON.parse(jsonUser);
        if (obj) {
            setUserId(obj.email);
            console.log('user', userId);
        }
        const fetchProjects = async () => {
            const response = await axios.get('https://pako-ai.herokuapp.com/getAllProjects');
            setProjects(response.data);
        };

        const fetchUsers = async () => {
            const response = await axios.get('https://pako-ai.herokuapp.com/usersList');
            setUsers(response.data);
        };

        const fetchAdmins = async () => {
            const response = await axios.get('https://pako-ai.herokuapp.com/getProjectAdmins');
            setAdmins(response.data);
        };

        const fetchModels = async () => {
            const response = await axios.get('https://pako-ai.herokuapp.com/getAllModels');
            setModels(response.data);
        };

        fetchAdmins();
        fetchProjects();
        fetchUsers();
        fetchModels();
    }, [userId]);

    const handleAddProject = async () => {
        await axios.post('https://pako-ai.herokuapp.com/createProject', { name: newProjectName });
        const response = await axios.get('https://pako-ai.herokuapp.com/getAllProjects');
        setProjects(response.data);
        setNewProjectName('');
    };

    const handleMakeAdmin = async () => {
        await axios.post('https://pako-ai.herokuapp.com/createProjectAdmin', {
            projectId: selectedProject.id,
            userId: selectedUser,
        });
        setSelectedUser('');
        setSelectedProject('');
        const response = await axios.get('https://pako-ai.herokuapp.com/getProjectAdmins');
        setAdmins(response.data);
    };

    const handleAddModel = async (modelName) => {
        await axios.post('https://pako-ai.herokuapp.com/addModel', { name: modelName });
        const response = await axios.get('https://pako-ai.herokuapp.com/getAllModels');
        setModels(response.data);
        setNewModelName('');
    };

    return (
        <div className="admin-container">
            <h2 className="more-bottom">Admin page</h2>

            <Tabs defaultActiveKey="projects" id="admin-tabs">
                {/* Projects Tab */}
                <Tab eventKey="projects" title="Projects">
                    <div class="tab-container">
                    <ul>
                        {projects.map((project) => (
                            <li key={project.id}>
                                Name: {project.name} Id: {project.id}
                            </li>
                        ))}
                    </ul>
                    <br />
                    <br />
                    <div>
                        <input
                            type="text"
                            value={newProjectName}
                            onChange={(e) => setNewProjectName(e.target.value)}
                        />
                        <button onClick={handleAddProject}>Create Project</button>
                    </div>
                    </div>
                </Tab>

                {/* Models Tab */}
                <Tab eventKey="models" title="Models">
                    <div class="tab-container">
                    <ul>
                        {models.map((model) => (
                            <li key={model.id}>{model.name}</li>
                        ))}
                    </ul>
                    <div>
                        <input
                            type="text"
                            value={newModelName}
                            onChange={(e) => setNewModelName(e.target.value)}
                        />
                        <button onClick={() => handleAddModel(newModelName)}>Add Model</button>
                    </div>
                    </div>
                </Tab>

                {/* Admins Tab */}
                <Tab eventKey="admins" title="Admins">
                    <div class="tab-container">
                    <h3>Make a user admin for a project:</h3>
                    <div>
                        <DropdownButton id="user-dropdown-button" title={selectedUser || 'Select a user'} variant="light">
                            {users.map((user) => (
                                <Dropdown.Item key={user.email} onClick={() => setSelectedUser(user.email)}>
                                    {user.email}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>

                        <DropdownButton
                            id="project-dropdown-button"
                            title={selectedProject ? selectedProject.name : 'Select a project'}
                            variant="light"
                        >
                            {projects.map((project) => (
                                <Dropdown.Item key={project.id} onClick={() => setSelectedProject(project)}>
                                    {project.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>

                        <button
                            className="wizard-button"
                            disabled={!selectedUser || !selectedProject}
                            onClick={handleMakeAdmin}
                        >
                            Make Admin
                        </button>
                    </div>

                    <table>
                        <thead>
                        <tr>
                            <th>Email</th>
                            <th>Project ID</th>
                        </tr>
                        </thead>
                        <tbody>
                        {admins.map((admin) => (
                            <tr key={admin.id}>
                                <td>{admin.userId}</td>
                                <td>{admin.projectId}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

export default Admin;
