import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LogoutButton.css';
import Dropdown from 'react-bootstrap/Dropdown';

const LogoutButton = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get('https://pako-ai.herokuapp.com/profile', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                });
                setUsername(response.data.email);
            } catch (error) {
                console.log(error);
            }
        };
        checkToken();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        window.location.href = '/login';
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className="custom-dropdown-toggle circle">
                {username.charAt(0).toUpperCase()}
            </Dropdown.Toggle>


            <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LogoutButton;
