import React, { PureComponent } from 'react';

class CustomStats extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedProject: null, // add selectedProject to component's state
        };
    }

    setSelectedProject = (obj) => {
        this.setState({ selectedProject: obj }); // set selectedProject in component's state
    };

    async componentDidMount() {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const obj = JSON.parse(selectedProjectStorage);
            this.setSelectedProject(obj);
            const selectedProjectResponse = await fetch(`https://pako-ai.herokuapp.com/loop?projectId=${obj.id}`);
            const selectedProjectResult = await selectedProjectResponse.json();
            this.setState({ data: selectedProjectResult });

            const currentDate = new Date();
            const lastMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            const lastMonthResponse = await fetch(`https://pako-ai.herokuapp.com/loop?projectId=${obj.id}&startDate=${lastMonthDate.toISOString()}`);
            const lastMonthResult = await lastMonthResponse.json();
            this.setState(prevState => ({ data: [...prevState.data, ...lastMonthResult] }));
        }
    }



    render() {
        const { data } = this.state;
        const filteredData = data.filter((item) => item.date);
        const chartData = filteredData.reduce((acc, item) => {
            const date = new Date(item.date);
            const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
            const score = item.score || 0;
            if (acc[formattedDate]) {
                acc[formattedDate].total++;
                acc[formattedDate].sum += score;
                acc[formattedDate].average = acc[formattedDate].sum / acc[formattedDate].total;
            } else {
                acc[formattedDate] = {
                    name: formattedDate,
                    total: 1,
                    sum: score,
                    average: score,
                    date: date.getTime()
                };
            }
            return acc;
        }, {});

        const chartDataArray = Object.values(chartData).sort((a, b) => a.date - b.date);

        // Calculate the number for the last 7 days
        const lastSevenDaysData = chartDataArray.slice(-7);
        const totalNumber = lastSevenDaysData.reduce((acc, item) => acc + item.total, 0);

        // Calculate the number for the last 30 days
        const lastThirtyDaysData = chartDataArray.slice(-30);
        const totalNumberLastMonth = lastThirtyDaysData.reduce((acc, item) => acc + item.total, 0);

        return (
            <div>
                <div style={{
                    width: '100%',
                    margin: '0 auto',
                    marginTop: '20px',
                    marginRight: '20px',
                    border: '1px solid #dedede',
                    borderRadius: '10px',
                    padding: '10px',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                }}>
                    {/*<span className="section-title">Your stats this week</span>*/}
                    <h4><strong>Your stats this week</strong></h4>

                    <div>
                        <span>Last 7 days: {totalNumber}</span>
                    </div>
                    <div>
                        <span>Last 30 days: {totalNumberLastMonth}</span>
                    </div>
                </div>
            </div>
        );
    }

}

export default CustomStats;
