import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Dashboard.css";
import '@fortawesome/fontawesome-free/css/all.css';
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Sidebar from "./components/Sidebar";
import Queries from "./components/Queries";
import Upload from "./components/Upload";
import Intentions from "./components/Intentions";
import Settings from "./components/Settings";
import Admin from "./components/Admin";
import Analytics from "./components/Analytics";
import React, { useState,useEffect } from 'react';
import LogoutButton from './components/LogoutButton';
import { TypeAnimation } from 'react-type-animation';
import AppSelector from './components/AppSelector';
import Chat from './components/Chat';
import Whatsapp from './components/Whatsapp';
import Call from './components/Call';
import Reminders from './components/Reminders';

function App() {
    return (
        <BrowserRouter>
            <Dashboard />
        </BrowserRouter>
    );
}

function Dashboard() {
    const [username, setUsername] = useState('');
    const [isAdmin, setAdmin] = useState(false);
    const [selectedProject, setSelectedProject] = useState([]);

    const access_token = localStorage.getItem('access_token');
    const [tokenIsValid, setTokenIsValid] = useState(false);
    useEffect(() => {
        const checkToken = async () => {
            const response = await fetch('https://pako-ai.herokuapp.com/profile', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            const data = await response.json();
            localStorage.setItem('user', JSON.stringify({email: data.email}));
            setUsername(data.email);
            setAdmin(data.role || data.role === 'admin');
            setTokenIsValid(data.valid);
        }

        checkToken();
    }, []);

    useEffect(() => {
        const checkSelectedProject = async () => {
            const selectedProjectStorage = localStorage.getItem('selectedProject');

            if (selectedProjectStorage) {
                const obj = JSON.parse(selectedProjectStorage);
                if (obj && obj.id) {
                    setSelectedProject(obj);
                }
            }
        };

        checkSelectedProject();
    }, []);

    const validAccess  = (!tokenIsValid || !tokenIsValid.valid);
    if (!access_token || access_token === 'undefined' || !validAccess) {
        window.location.href = '/login'; // Redirect to login page if access_token is not present
    } else {
        return (
            <div>
                {/*<header style={{backgroundColor: "#252525", height: "150px!important", width: "100%"}}>*/}
                {/*    <div className="containHead">*/}
                {/*        <div className="logo" style={{padding: '1px'}}>*/}
                {/*            <span*/}
                {/*                className="logo-label">🤓 Superhuman AI Agent for Customer & Employee Support</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</header>*/}


                <div className="main-container">
                    <Sidebar/>
                    {/*<header style={{backgroundColor: "rgb(255 255 255)", height: "150px!important", width: "100%"}}>*/}
                    {/*    <div className="containHead">*/}
                    {/*        <div className="logo" style={{display: 'flex', alignItems: 'center', padding: '5px'}}>*/}
                    {/*            <img src="/alphalogosmall.png" alt="Chat AI Logo" style={{width: '25px', paddingTop: '0px', marginRight: '10px'}} />*/}
                    {/*            /!*<span className="logo-label">🤓 Refine your AI implementation with actionable insights</span>*!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</header>*/}


                    <div className="secondNavbar" style={{
                        backgroundColor: "#ffffff",
                        borderBottom: "1px solid #dedede",
                        borderTop: "1px solid #dedede"
                    }}>
                        <div className="containHead" style={{display: "flex", alignItems: "center"}}>
                            {/*<div className="logo" style={{padding: '5px', marginRight: 0}}>*/}
                            {/*    🏢 Our Amazing Company /*/}
                            {/*</div>*/}
                            🏢 <AppSelector></AppSelector>
                            {/*<UserShort></UserShort>*/}
                            {/*<UserShort></UserShort>*/}

                            <span style={{
                                fontSize: '14px',
                                color: '#808080',
                                marginRight: '20px',
                                marginLeft: 'auto',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }}>
                        {/*🛝 Playground*/}
                    </span>
                            <LogoutButton></LogoutButton>
                        </div>
                    </div>

                    <Switch>
                        <Route exact path="/">
                            <div style={{ marginTop: '0px' }}>
                                {(!selectedProject || !selectedProject.id) ? (
                                    <TypeAnimation
                                        sequence={[`Welcome ${username}! Please select a project from the dropdown. If you don't see any project, reach out to the Alpha Chat team.`]}
                                        wrapper="span"
                                        cursor={true}
                                        style={{ fontSize: '1.2em', display: 'inline-block' }}
                                    />
                                ) : (
                                    <div class="main-dashboard" style={{ marginTop: '0px', padding: '15px' ,paddingTop: '0px',  paddingRight: '25px', paddingLeft: '25px' }}>
                                        <Analytics />
                                    </div>
                                )}
                            </div>
                        </Route>
                        <Route path="/dashboard/Whatsapp">
                            <div>
                                <Whatsapp/>
                            </div>
                        </Route>
                        <Route path="/dashboard/Call">
                            <div style={{marginTop: '20px'}}>
                                <Call/>
                            </div>
                        </Route>
                        <Route path="/dashboard/reminders">
                            <div style={{marginTop: '20px'}}>
                                <Reminders/>
                            </div>
                        </Route>
                        <Route path="/dashboard/Chat">
                            <div>
                                <Chat/>
                            </div>
                        </Route>
                        <Route path="/dashboard/queries">
                            <div style={{marginTop: '20px'}}>
                                <Queries/>
                            </div>
                        </Route>
                        <Route path="/dashboard/analytics">
                            <div style={{marginTop: '0px', padding: '25px', paddingTop: '0px'}}>
                                <Analytics/>
                            </div>
                        </Route>
                        <Route path="/dashboard/upload">
                            <Upload/>
                        </Route>
                        <Route path="/dashboard/intentions">
                            <Intentions/>
                        </Route>
                        <Route path="/dashboard/settings">
                            <Settings/>
                        </Route>
                        {isAdmin && (
                            <Route path="/dashboard/admin">
                                <Admin/>
                            </Route>
                        )}
                    </Switch>
                </div>
            </div>
        );
    }
}
export default App;
