import React, { Component } from 'react';
import './CallsFlows.css';
import axios from 'axios'; // Import axios

class CallsFlows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stages: [
                {
                    id: '',
                    name: '',
                    speech: '',
                    domain: [{ expectedAnswer: '', followingStep: '' }],
                },
            ],
        };
    }
    componentDidMount() {
        this.getFlowByProjectId();
    }
    getFlowByProjectId = () => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const selectedProject = JSON.parse(selectedProjectStorage);
            const projectId = selectedProject.id;

            // Call your API to get flow data by projectId using axios
            axios.get(`http://pako-ai.herokuapp.com/getFlow?projectId=${projectId}`)
                .then(response => {
                    this.setState({ stages: response.data.stages });
                })
                .catch(error => {
                    // Handle error
                    console.error('Error fetching flow:', error);
                });
        }
    };

    handleSaveFlow = () => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const selectedProject = JSON.parse(selectedProjectStorage);
            const projectId = selectedProject.id;
            const flowData = {
                projectId: projectId, // Add projectId to the flow data
                stages: this.state.stages,
            };

            // Call your API to save the flow data using axios
            axios.post(`http://pako-ai.herokuapp.com/saveFlow`, flowData)
                .then(response => {
                    // Handle success
                    console.log('Flow saved successfully:', response);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error saving flow:', error);
                });
        }
    };


    handleInputChange = (event, rowIndex, field, nestedField, nestedIndex) => {
        const { value } = event.target;
        this.setState((prevState) => {
            const updatedStages = [...prevState.stages];
            if (nestedIndex !== undefined) {
                updatedStages[rowIndex][field][nestedIndex][nestedField] = value;
            } else {
                updatedStages[rowIndex][field] = value;
            }
            return { stages: updatedStages };
        });
    };

    isStageFilled = (stage) => {
        return (
            stage.id.trim() !== '' &&
            stage.name.trim() !== '' &&
            stage.speech.trim() !== '' &&
            stage.domain.every(
                (domainEntry) =>
                    domainEntry.expectedAnswer.trim() !== '' &&
                    domainEntry.followingStep.trim() !== ''
            )
        );
    };

    handleAddStage = () => {
        const newStage = {
            id: '',
            name: '',
            speech: '',
            domain: [{ expectedAnswer: '', followingStep: '' }],
        };

        if (!this.state.stages.some(this.isStageFilled)) {
            alert('Please fill all fields in the current stage before adding a new one.');
            return;
        }

        this.setState((prevState) => ({
            stages: [...prevState.stages, newStage],
        }));
    };

    handleAddDomainEntry = (rowIndex) => {
        this.setState((prevState) => {
            const updatedStages = [...prevState.stages];
            updatedStages[rowIndex].domain.push({ expectedAnswer: '', followingStep: '' });
            return { stages: updatedStages };
        });
    };

    render() {
        return (
            <div className="calls-flows">
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Speech</th>
                        <th colSpan="2">Domain</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.stages.map((stage, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                <label>ID</label>
                                <input
                                    type="text"
                                    value={stage.id}
                                    onChange={(event) => this.handleInputChange(event, rowIndex, 'id')}
                                />
                            </td>
                            <td>
                                <label>Name</label>
                                <input
                                    type="text"
                                    value={stage.name}
                                    onChange={(event) => this.handleInputChange(event, rowIndex, 'name')}
                                />
                            </td>
                            <td>
                                <label>Speech</label>
                                <textarea
                                    value={stage.speech}
                                    onChange={(event) => this.handleInputChange(event, rowIndex, 'speech')}
                                />
                            </td>
                            <td>
                                {stage.domain.map((domainEntry, nestedIndex) => (
                                    <div key={nestedIndex}>
                                        <label>Expected Answer</label>
                                        <textarea
                                            value={domainEntry.expectedAnswer}
                                            onChange={(event) =>
                                                this.handleInputChange(event, rowIndex, 'domain', 'expectedAnswer', nestedIndex)
                                            }
                                        />
                                        <label>Following Step</label>
                                        <input
                                            type="text"
                                            value={domainEntry.followingStep}
                                            onChange={(event) =>
                                                this.handleInputChange(event, rowIndex, 'domain', 'followingStep', nestedIndex)
                                            }
                                        />
                                    </div>
                                ))}
                                <button onClick={() => this.handleAddDomainEntry(rowIndex)}>Add New Domain</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button onClick={this.handleAddStage}>Add New Stage</button>
                <button onClick={this.handleSaveFlow}>Save Flow</button>
            </div>
        );
    }
}

export default CallsFlows;
