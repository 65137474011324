import React, { useState, useEffect } from "react";
import "./FlowUploader.css";




function FlowUploader() {
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [showFiles, setShowFiles] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [deleteFile, setDeleteFile] = useState(null);
    const [currentStep, setCurrentStep] = useState('Not Tuned');
    const [agentName, setAgentName] = useState("");


    const handleNextClick = () => {
        if (agentName.trim()) {
            setCurrentStep('Not-tuned-with-name');
            sessionStorage.setItem('agentName', agentName); // Storing agent name to sessionStorage
        }
    };
    // The handler that saves the agent name to session storage
    const handleAgentNameChange = (e) => {
        const newName = e.target.value;
        setAgentName(newName);
        sessionStorage.setItem("agentName", newName);
    };
    useEffect(() => {
        const storedProject = localStorage.getItem("selectedProject");
        if (storedProject) {
            setSelectedProject(JSON.parse(storedProject));
        }
        // Retrieving agentName from sessionStorage
        const storedAgentName = sessionStorage.getItem('agentName');
        if (storedAgentName) {
            setAgentName(storedAgentName);
            setCurrentStep('Not-tuned-with-name'); // Set the step accordingly if a name exists
        }
    }, []);

    const handleShowUpload = () => {
        setShowFiles(!showFiles);
    };
    const handleCallNow = () => {
        setCurrentStep("Calling");
    };

    const stepDescriptions = {
        'Not Tuned':  { label: 'Give a name to your agent', description: `Fill with a catching name and then click next.` } ,
        'Not-tuned-with-name': { label: 'Start', description: `I am ${agentName || '[Name]'}, please upload the scheme and I'll be ready to support you as the best agent!` },
        'Uploading': { label: '1/3', description: 'Uploading your scheme...' },
        'Analyzing': { label: '2/3', description: 'Analyzing the content...' },
        'Tuning AI': { label: '3/3', description: 'Tuning the AI models...' },
        'Completed': { label: 'Finished.', description: 'Your agent is ready! Test it now...' }
    };

    const renderActiveTabContent = () => {
                return (
                    <div>
                        <div className="main-upload">
                            {(
                                <div className="loading-container">
                                    <h5>
                                        <strong>{stepDescriptions[currentStep]?.label || 'N/A'}</strong> - {stepDescriptions[currentStep]?.description || 'Description not available'}
                                    </h5>

                                    {currentStep === 'Not Tuned' && (
                                        <>

                                            <div className="overlay-container"> {/* Added this container */}
                                                <div className="agent-name-container cursor-effect">
                                                    {/*<label htmlFor="agentName">Agent Name: </label>*/}
                                                    <input
                                                        className="modern-input"
                                                        type="text"
                                                        id="agentName"
                                                        placeholder="Agent Name"
                                                        value={agentName}
                                                        onChange={handleAgentNameChange}
                                                    />
                                                </div>
                                                <button className="wizard-button" onClick={handleNextClick} disabled={!agentName.trim()}>
                                                    NEXT
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {currentStep === 'Completed' &&
                                    <div className="completed-container">
                                        <button className="empty-button centered-button" onClick={handleCallNow}>
                                            <strong>CALL NOW +1 (717) 550-1675</strong>
                                        </button>
                                    </div>
                                    }


                                </div>
                            )}
                            <br />
                            {files && files.length > 0 ? (
                                <div>
                                    <p>Update coming from:</p>
                                    <ul>
                                        {files.map((file, index) => (
                                            <li className="no-menu" key={index}>
                                                {file.filename}. {file.created_at}. <br />
                                                <a    className="download-link"
                                                    href={`https://pako-ai.herokuapp.com/download?filename=${file.filename}&projectId=${file.projectId}&fileType=flow`}
                                                >
                                                    {/*<button className="empty-button">Download</button>*/}
                                                </a>
                                                {/* Move this button outside of the outer button */}
                                                {currentStep !== 'Uploading' && currentStep !== 'Analyzing' && currentStep !== 'Tuning AI' && (
                                                    <button
                                                        className="retune-button"
                                                        onClick={() => handleDelete(file)}
                                                    >
                                                        Retune
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div>
                                    {currentStep !== 'Uploading' && currentStep !== 'Analyzing' && currentStep !== 'Tuning AI' && currentStep !== 'Not Tuned' && (
                                        <button className="wizard-button" onClick={handleShowUpload}>
                                        {showFiles ? "Close" : "Upload Talk Stack scheme file"}
                                    </button>
                                    )}
                                </div>
                            )}




                            {deleteFile && (
                                <div className="delete-popup">
                                    <h3>Are you sure you want to delete {deleteFile.filename}?</h3>
                                    <button onClick={handleConfirmDelete}>Yes</button>
                                    <button onClick={() => setDeleteFile(null)}>No</button>
                                </div>
                            )}

                            {files && files.length === 0 && showFiles && (currentStep !== 'Uploading' && currentStep !== 'Analyzing' && currentStep !== 'Tuning AI') && (
                                <form className="form-file" onSubmit={handleSubmit}>
                                    <input
                                        type="file"
                                        accept=".pdf,.doc,.docx, .csv, .xls"
                                        onChange={handleFileChange}
                                    />
                                    {file && <button type="submit">Confirm</button>}
                                </form>
                            )}


                        </div>
                    </div>
                );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!file || (!file.type.includes("pdf") && !file.type.includes("word"))) {
            alert("Please select a PDF or DOC file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectId", selectedProject.id);
        formData.append("fileType", 'flow');

        setCurrentStep('Uploading');

        fetch("https://pako-ai.herokuapp.com/upload", {
            method: "POST",
            body: formData,
        })
            .then(() => {
                fetch(
                    `https://pako-ai.herokuapp.com/files?projectId=${selectedProject.id}&fileType=flow`
                )
                    .then((response) => response.json())
                    .then((data) => {
                        setFile(null); // Reset the file state here
                        setTimeout(() => {
                            setCurrentStep('Analyzing');
                            setTimeout(() => {
                                setCurrentStep('Tuning AI');
                                setTimeout(() => {
                                    setCurrentStep(null);
                                    setFiles(data);
                                    if (data && data.length > 0) {
                                        setCurrentStep('Completed');
                                    } else {
                                        setCurrentStep('Not Tuned');
                                    }
                                    setCurrentStep('Completed');
                                }, 5000);
                            }, 3000);
                        }, 3000);

                    });
            });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDelete = (file) => {
        setDeleteFile(file);
    };

    const handleConfirmDelete = () => {
        fetch(
            `https://pako-ai.herokuapp.com/files/${deleteFile.filename}?projectId=${selectedProject.id}&fileType=flow`,
            { method: "DELETE" }
        ).then(() => {
            setDeleteFile(null);
            setFiles(files.filter((file) => file.filename !== deleteFile.filename));
        });
    };

    useEffect(() => {
        if (selectedProject) {
            fetch(
                `https://pako-ai.herokuapp.com/files?projectId=${selectedProject.id}&fileType=flow`
            )
                .then((response) => response.json())
                .then((data) => setFiles(data));
        }
    }, [selectedProject]);

    return (
        <div>
            {/* Render the active tab content */}
            {renderActiveTabContent()}
        </div>
    );
}

export default FlowUploader;
