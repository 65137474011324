import React, { useState, useEffect } from 'react';
import CallsTranscript from './CallsTranscript';
import Batches from './Batches';
import UseCases from './UseCases';
import CallsFlows from './CallsFlows';
import FlowUploader from './FlowUploader'
import { v4 as uuidv4 } from 'uuid'; // Import the UUID generator function
import CarsomeUploader from './CarsomeUploader';
import './Call.css';


const Call = () => {
    const [activeTab, setActiveTab] = useState('batch-calls');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstText, setFirstText] = useState('');
    const [projectId, setProjectId] = useState('');
    const [fields] = useState([{ fieldName: '', value: '' }]);
    const [fromNumber, setFromNumber] = useState(localStorage.getItem('selectedFromNumber') || '+3197010255242');
    const yesPhrases = ['Ada', 'Ya', 'Yea', 'Ade', 'Betul', 'Ok'];
    const noPhrases = ['Tidak', 'Tak', 'Tade', 'Tak Ada', 'Tak Pernah', 'Tak Pernah Ade', 'Tak Ade', 'Tak Sure'];
    const [extraVariables, setExtraVariables] = useState([]);

    useEffect(() => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const selectedProject = JSON.parse(selectedProjectStorage);
            setProjectId(selectedProject.id);
        }
    }, []);
    const handleUploadFileComplete = (data) => {
        setExtraVariables(data[0]);
        if (data[0].phone) {
            setPhoneNumber(data[0].phone)
        }
        // Now extraVariables in ParentComponent has the data from the uploaded file
    };

    const handleNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleFromNumberChange = (number) => {
        setFromNumber(number);
        localStorage.setItem('selectedFromNumber', number);
    };

    const handleFirstTextChange = (event) => {
        setFirstText(event.target.value);
    };

    const handleCall = () => {
        const uuid = uuidv4();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to: phoneNumber,
                from: fromNumber || '+3197010255242',
                message: firstText, voiceId: 'DHPKCpMbgL6Gp446ss8G', voiceProvider: 'elevenlabs', projectId: projectId, ip: uuid, language: 'english' }),
        };

        // Fetch the API endpoint for making the call
        fetch('https://talkstackcall-ba143e91a8a5.herokuapp.com/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleCallV2 = () => {
        const uuid = uuidv4();
        const speechContexts = [
            { phrases: ["Semenanjung"] },
            { phrases: ["Labuan"] },
            { phrases: ["Langkawi"] },
            { phrases: ["Leonardo"] },
            { phrases: yesPhrases },
            { phrases: noPhrases }
        ];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to: phoneNumber,
                speechContexts: speechContexts,
                message: firstText, voiceId: 'DHPKCpMbgL6Gp446ss8G', voiceProvider: 'elevenlabs', projectId: projectId, ip: uuid, language: 'english' }),
        };

        // Fetch the API endpoint for making the call
        fetch('https://talkstackcall-ba143e91a8a5.herokuapp.com/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleCallV3 = () => {
        const uuid = uuidv4();
        const speechContexts = [
            { phrases: ["Semenanjung"] },
            { phrases: ["Labuan"] },
            { phrases: ["Langkawi"] },
            { phrases: ["Leonardo"] },
            { phrases: yesPhrases },
            { phrases: noPhrases }
        ];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to: phoneNumber,
                speechContexts: speechContexts,
                message: firstText, voiceId: 'DHPKCpMbgL6Gp446ss8G', voiceProvider: 'elevenlabs', projectId: projectId, ip: uuid, language: 'bahasa' }),
        };

        // Fetch the API endpoint for making the call
        fetch('https://talkstack-call-1.azurewebsites.net/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const handleCallV4 = () => {
        const uuid = uuidv4();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to: phoneNumber, message: firstText, projectId: projectId, ip: uuid, language: 'italian' }),
        };

        // Fetch the API endpoint for making the call
        fetch('https://talkstack-call-1.azurewebsites.net/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleCallGoogle = (language) => {
        const uuid = uuidv4();
        const extraVariables = fields.reduce((acc, field) => {
            if (field.fieldName) {
                acc[field.fieldName] = field.value;
            }
            return acc;
        }, {});
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to: phoneNumber, message: firstText, projectId: projectId, ip: uuid,
                voiceId: 'ms-MY-Wavenet-C',
                extraVariables: extraVariables,
                from: fromNumber,
                speechProvider: 'whisper',
                voiceQuality: 'telephony-class-application', // Audio device profile or effectsProfileId
                language: language, voiceProvider: 'google' }),
        };

        // Fetch the API endpoint for making the call
        fetch('https://talkstack-call-1.azurewebsites.net/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleCallGoogleDetection = (language, detection = 'googleChirp') => {
        const uuid = uuidv4();
        let speechContexts = [];

        if (language === 'bahasa') {
            speechContexts = [
                { phrases: ["Semenanjung"] },
                { phrases: ["Labuan"] },
                { phrases: ["Langkawi"] },
                { phrases: yesPhrases },
                { phrases: noPhrases }
            ];
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ to: phoneNumber, message: firstText, projectId: projectId, ip: uuid,
                voiceId: 'ms-MY-Wavenet-C',
                speechProvider: 'google',
                extraVariables: extraVariables,
                from: fromNumber,
                speechContexts: speechContexts,
                voiceQuality: 'telephony-class-application', // Audio device profile or effectsProfileId
                language: language,
                ...(language === 'bahasa'
                    ? { speechModel: 'default' }
                    : language === 'english'
                        ? { speechModel: 'phone_call' }
                        : {}),
                voiceProvider: 'google' }),
        };

        // Fetch the API endpoint for making the call
        fetch('https://talkstackcall-ba143e91a8a5.herokuapp.com/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleCallGoogleDetectionWithEleven = (language) => {
        const uuid = uuidv4();
        let speechContexts = [];
        if (language === 'bahasa') {
            speechContexts = [
                { phrases: ["Semenanjung"] },
                { phrases: ["Labuan"] },
                { phrases: ["Leonardo"] },
                { phrases: ["Langkawi"] },
                { phrases: yesPhrases },
                { phrases: noPhrases }
            ];
        } else {
            speechContexts = [
                { phrases: ["Semenanjung"] },
                { phrases: ["Labuan"] },
                { phrases: ["Leonardo"] },
                { phrases: ["Langkawi"] },
                { phrases: ["Grab"] }
            ];
        }

        let voiceId = 'DHPKCpMbgL6Gp446ss8G';
        if (language === 'italian') {
            voiceId = 'T2CQu6JzhP6mxSvsTmoT';
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                to: phoneNumber,
                message: firstText,
                speechProvider: 'google',
                voiceQuality: 4,
                speechContexts: speechContexts,
                voiceId: voiceId,
                voiceProvider: 'elevenlabs',
                projectId: projectId,
                ip: uuid,
                extraVariables: extraVariables,
                language: language,
                ...(language === 'bahasa'
                    ? { speechModel: 'default' }
                    : language === 'english'
                        ? { speechModel: 'phone_call' }
                        : {})

            }),
        };
        // Fetch the API endpoint for making the call
        fetch('https://talkstackcall-ba143e91a8a5.herokuapp.com/twilio/stream/call', requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data); // Handle the response data as needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const renderActiveTabContent = () => {
        switch (activeTab) {
            case 'new-flow':
                return <FlowUploader></FlowUploader>;
            case 'batch-calls':
                return <Batches />;
            case 'use-cases':
                return <UseCases />;
            case 'history':
                return <CallsTranscript />;
            case 'flows':
                return <CallsFlows />;
            case 'test-call':
                return (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px' }}>
                            <label>
                                <input
                                    type='radio'
                                    value='+3197010255242'
                                    checked={fromNumber === '+3197010255242'}
                                    onChange={() => handleFromNumberChange('+3197010255242')}
                                />
                                From Netherlands
                            </label>
                            <label>
                                <input
                                    type='radio'
                                    value='+60162999547'
                                    checked={fromNumber === '+60162999547'}
                                    onChange={() => handleFromNumberChange('+60162999547')}
                                />
                                From Malaysia
                            </label>
                        </div>


                        <form>
                            <label htmlFor='phoneNumber'>Phone Number:</label>
                            <input
                                type='text'
                                id='phoneNumber'
                                value={phoneNumber}
                                onChange={handleNumberChange}
                            />
                            <br />
                            <label htmlFor='firstText'>Welcome Text:</label>
                            <textarea
                                id='firstText'
                                value={firstText}
                                onChange={handleFirstTextChange}
                                rows={4}
                                cols={50}
                            />
                        </form>
                        <button onClick={handleCall}>Call V1</button>
                        <button onClick={handleCallV2}>Call V2 Labs</button>
                        <button onClick={handleCallV3}>Call Bahasa 11 Labs</button>
                        <button onClick={handleCallV4}>Call Italian Labs</button>
                        <button onClick={() => handleCallGoogle('english')}>Call Google English</button>
                        <button onClick={() => handleCallGoogle('bahasa')}>Call Google Bahasa</button>
                        <button onClick={() => handleCallGoogle('italian')}>Call Google Italian</button>
                        <button onClick={() => handleCallGoogleDetection('bahasa')}>Call Google Detect Bahasa</button>
                        <button onClick={() => handleCallGoogleDetection('english')}>Call Google Detect English</button>
                        <button onClick={() => handleCallGoogleDetection('english', 'googleChirp')}>Call GoogleChirp Detect English</button>
                        <button onClick={() => handleCallGoogleDetectionWithEleven('bahasa')}>Call Google Detect Bahasa 11 Labs</button>
                        <button onClick={() => handleCallGoogleDetectionWithEleven('english')}>Call Google Detect English 11 Labs</button>
                        <button onClick={() => handleCallGoogleDetectionWithEleven('italian')}>Call Google Detect Italian 11 Labs</button>

                        <CarsomeUploader onUploadComplete={handleUploadFileComplete} />

                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div class='container-call'>
            {/* Tab Navigation */}
            <div className='tab-container'>
                <button className={activeTab === 'new-flow' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('new-flow')}>
                    Flow Uploader
                </button>
                <button className={activeTab === 'batch-calls' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('batch-calls')}>
                    Batch Calls
                </button>
                <button className={activeTab === 'history' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('history')}>
                    Logs and Transcript
                </button>
                <button className={activeTab === 'test-call' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('test-call')}>
                    Test Call
                </button>
                <button className={activeTab === 'use-cases' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('use-cases')}>
                    Use Cases
                </button>
                <button className={activeTab === 'flows' ? 'active-tab' : 'inactive-tab'} onClick={() => setActiveTab('flows')}>
                    Flows
                </button>
            </div>
            {/* Render the active tab content */}
            {renderActiveTabContent()}
        </div>
    );
};

export default Call;
