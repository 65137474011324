import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

function AppSelector() {
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState('Please select a project');

    useEffect(() => {
        const checkToken = async () => {
            const response = await fetch('https://pako-ai.herokuapp.com/profile', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            const data = await response.json();
            localStorage.setItem('user', JSON.stringify({email: data.email}));

            const jsonUser = localStorage.getItem('user');
            let obj = JSON.parse(jsonUser);
            let email;

            if (obj) {
                email = obj.email;
            }


            fetch('https://pako-ai.herokuapp.com/getProjects?userId='+ email)
                .then(response => response.json())
                .then(data => {
                    setApps(data);
                    const selectedProjectStorage = localStorage.getItem('selectedProject');
                    if (selectedProjectStorage) {
                        const obj = JSON.parse(selectedProjectStorage);
                        setSelectedApp(obj.name);
                    } else if (data.length === 1) {  // Auto-select if only one app available
                        const singleApp = data[0];
                        setSelectedApp(singleApp.name);
                        localStorage.setItem('selectedProject', JSON.stringify(singleApp));
                    }
                })
                .catch(error => console.log(error));
        }
        checkToken();
    }, []);

    function handleAppSelect(app) {
        setSelectedApp(app.name);
        localStorage.setItem('selectedProject', JSON.stringify(app));
        window.location.reload();
    }

    if (apps.length === 1) {
        const app = apps[0];
        return <span>{app.name}</span>;
    }

    return (
        <DropdownButton id="app-dropdown-button" title={selectedApp} variant="light">
            {apps.map(app => (
                <Dropdown.Item key={app.id} onClick={() => handleAppSelect(app)}>
                    {app.name}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}

export default AppSelector;
