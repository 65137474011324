import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./LoginPage.css";
import { TypeAnimation } from 'react-type-animation';


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const access_token = localStorage.getItem('access_token');

    if (access_token && access_token !== 'undefined') {
        window.location.href = '/'; // Redirect to login page if access_token is not present
        return null;
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://pako-ai.herokuapp.com/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });
            if (response.status === 200) {
                const data = await response.json();
                localStorage.setItem('access_token', data.access_token);
                window.location.href = '/'; // Redirect to protected page after successful login
            } else {
                // handle error here, for example by displaying an error message
            }
        } catch (error) {
            console.error(error);
            // handle the error here, for example by displaying an error message to the user
        }
    };

    return (
        <div className="login-container">
            <header style={{backgroundColor: "rgb(74, 74, 74)", height: "150px!important", width: "100%"}}>
                <div className="containHead">
                    <div className="logo" style={{  padding: '5px' }}>
                        <span className="logo-label">🤓 Superhuman AI Agent for Customer & Employee Support</span>
                    </div>
                </div>
            </header>
            <img src="/talkStackAi.png" alt="Chat AI Logo" style={{ display: 'block', margin: '0 auto', width: '300px', marginTop: '45px', marginBottom: '25px', }} />
            <TypeAnimation
                sequence={['Welcome back! Please enter your login credentials to access your account.']}
                wrapper="span"
                cursor={true}
                style={{ fontSize: '1.2em', display: 'inline-block' }}
            />
            <form onSubmit={handleLogin}>
                <label>
                    Email:
                    <input type="email" value={email} onChange={handleEmailChange} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </label>
                <button className="wizard-button login-button" type="submit">Log in</button>
            </form>
            <div>
                Don't have an account yet? <Link to="/register">Register here</Link>
            </div>
        </div>
    );
}

export default Login;
