import React, { useState } from 'react';
import "./LoginPage.css";
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const access_token = localStorage.getItem('access_token');
    const [errorMsg, setErrorMsg] = useState('');

    if (access_token && access_token !== 'undefined') {
        window.location.href = '/dashboard'; // Redirect to login page if access_token is not present
        return null;
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }
        const response = await fetch('http://127.0.0.1:5000/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });
        const data = await response.json();

        if (response.status === 400 && data.msg === "User already exists") {
            setErrorMsg(data.msg);
            return;
        }

        localStorage.setItem('access_token', data.access_token);
    };

    return (
        <div className="login-container">
            <header style={{backgroundColor: "#000000", height: "150px!important", width: "100%"}}>
                <div className="🏢 containHead">
                    <div className="logo" style={{  padding: '5px' }}>
                        <span className="logo-label">🤓 Superhuman AI Agent for Customer & Employee Support</span>
                    </div>
                </div>
            </header>
            <img src="/talkStackAi.png" alt="Chat AI Logo" style={{ display: 'block', margin: '0 auto', width: '200px', marginTop: '25px', }} />
            <TypeAnimation
                sequence={['Welcome to Chat AI, we need some information before starting.']}
                wrapper="span"
                cursor={true}
                style={{ fontSize: '1.2em', display: 'inline-block' }}
            />

            <form onSubmit={handleLogin}>
                <label>
                    Email:
                    <input type="email" value={email} onChange={handleEmailChange} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </label>
                <label>
                    Confirm Password:
                    <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                </label>
                <button className="wizard-button login-button" type="submit">Register</button>
                {errorMsg && <div className="error-message">{errorMsg}</div>}
            </form>
            <div>
                Do you already have an account? <Link to="/login">Login here</Link>
            </div>
        </div>
    );
}

export default Register;