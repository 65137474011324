import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { FaExclamationTriangle } from 'react-icons/fa';

function BrokeAnswers() {
    const selectedProjectStorage = localStorage.getItem('selectedProject');
    const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;

    const [outageData, setOutageData] = useState([]);

    useEffect(() => {
        if (projectId) {
            fetch(`https://pako-ai.herokuapp.com/outages?projectId=${projectId}`)
                .then(response => response.json())
                .then(data => setOutageData(data))
                .catch(error => {
                    console.log(error);
                    setOutageData([]);
                });
        }
    }, [projectId]);

    const getChartData = () => {
        if (outageData.length === 0) {
            // If there are no outages, return a flat line with value 0
            return [{ date: new Date(), value: 0 }];
        }

        const chartData = [];
        outageData.forEach(outage => {
            const date = new Date(outage.date);
            chartData.push({ date, value: 1 }); // Always set value to 1 to indicate an outage
        });

        return chartData;
    };

    const chartData = getChartData();

    const tickFormatter = (date) => {
        return new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
    };

    const numBrokeAnswers = outageData.length;
    let message = null;
    if (numBrokeAnswers > 0) {
        message = (
            <div className="broke-message">
                <FaExclamationTriangle /> You have {numBrokeAnswers} outage detected.
            </div>
        );
    } else {
        message = (
            <div className="broke-message">
                🟢 0 outage detected. No action needed.
            </div>
        );
    }

    return (
        <>
            <span className="section-title">Outage</span>
            <ResponsiveContainer width="100%" height={150}>
                <LineChart data={chartData} className="chart-container">
                    <XAxis dataKey="date" tickFormatter={tickFormatter} tick={{ fontSize: 12 }} />
                    <YAxis domain={[0, 1]} axisLine={false} />
                    <Tooltip />
                    <Line dataKey="value" stroke="Red" name="Broke" />
                </LineChart>
            </ResponsiveContainer>
            {message}
        </>
    );
}

export default BrokeAnswers;
