
function FeedbackChart({ projectId }) {
    // const [feedbackData, setFeedbackData] = useState([]);

    // useEffect(() => {
    //     fetch(`https://pako-ai.herokuapp.com/feedback?projectId=${projectId}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.length > 0) {
    //                 data.sort((a, b) => new Date(a.date) - new Date(b.date));
    //                 setFeedbackData(data);
    //             } else {
    //                 // Mock data if no results from the API
    //                 const mockData = [
    //                     { date: '2023-06-01', rating: "happy" },
    //                     { date: '2023-06-02', rating: "happy" },
    //                     { date: '2023-06-03', rating: "happy" },
    //                     { date: '2023-06-03', rating: "happy" },
    //                     // Add more mock data here if needed
    //                 ];
    //                 setFeedbackData(mockData);
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             // Handle the error condition if required
    //         });
    // }, [projectId]);

    return (
        <>
            <div style={{
                width: '100%',
                margin: '0 auto',
                minHeight: '355px',
                marginTop: '10px',
                marginRight: '20px',
                border: '1px solid #dedede',
                borderRadius: '10px',
                padding: '10px',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
            }}>
                <h4><strong>Customer Feedback</strong></h4>
                <h5><strong>Soon available</strong></h5>

                {/* No chart-related code */}
            </div>
        </>
    );
}

export default FeedbackChart;
