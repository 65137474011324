import React, { useState, useEffect } from 'react';
import QueriesChart from './QueriesChart';
import FeedbackChart from './FeedbackChart';
import BrokeAnswers from './BrokeAnswers';
import CustomStats from './CustomStats';
import ConversionsStats from './ConversionsStats';
import IntentionsPieChart from './IntentionsPieChart'

import './Analytics.css';

function Analytics() {
    const [data, setData] = useState([]);
    const [selectedProject, setSelectedProject] = useState();

    useEffect(() => {
        async function fetchData() {
            const selectedProjectStorage = localStorage.getItem('selectedProject');
            if (selectedProjectStorage) {
                const obj = JSON.parse(selectedProjectStorage);
                setSelectedProject(obj);
                const loopResponse = await fetch(
                    `https://pako-ai.herokuapp.com/loop?projectId=${obj.id}`
                );
                const result = await loopResponse.json();
                setData(result);
            }
        }
        fetchData();
    }, []);

    // function to calculate the number of responses by date
    const getChartData = () => {
        const chartData = {};
        data.forEach((item) => {
            const date = new Date(item.created_at).toLocaleDateString();
            if (chartData[date]) {
                chartData[date]++;
            } else {
                chartData[date] = 1;
            }
        });
        return chartData;
    };

    return (
        <div>
            {selectedProject && (
                <>

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            // marginBottom: '20px',
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <CustomStats data={getChartData()} />

                        </div>
                        {/*<div style={{ width: '20%' }}>*/}
                        {/*    <IntentionsPieChart/>*/}

                        {/*</div>*/}
                        {/*<div style={{ width: '33%' }}>*/}
                        {/*    <IntentionsPieChart/>*/}
                        {/*</div>*/}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                        }}
                    >
                        <div style={{ width: '50%' }}>
                            <ConversionsStats />
                        </div>
                        <div style={{ width: '50%' }}>
                            <QueriesChart data={getChartData()} />
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '0px',
                        }}
                    >
                        <div style={{ width: '50%' }}>
                            <IntentionsPieChart projectId={selectedProject.id} />
                        </div>
                        <div style={{ width: '50%' }}>
                            <FeedbackChart projectId={selectedProject.id} />
                        </div>
                        {/*<div style={{ width: '25%' }}>*/}
                        {/*    <IntentionsPieChart/>*/}
                        {/*</div>*/}
                        {/*<div style={{ width: '25%' }}>*/}
                        {/*    <IntentionsPieChart/>*/}
                        {/*</div>*/}
                    </div>



                    <div
                        style={{
                            width: '100%',
                            margin: '0 auto',
                            marginTop: '10px',
                            marginRight: '20px',
                            border: '1px solid #dedede',
                            borderRadius: '10px',
                            padding: '10px',
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                        }}
                    >
                        <BrokeAnswers projectId={selectedProject.id} />
                    </div>
                </>
            )}
        </div>
    );
}

export default Analytics;
