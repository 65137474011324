import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Employee.css";
import '@fortawesome/fontawesome-free/css/all.css';
import "font-awesome/css/font-awesome.min.css";

function Employee() {
    const [data, setData] = useState([]);
    const [selectedScore, setSelectedScore] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch('https://pako-ai.herokuapp.com/loop');
            const result = await response.json();
            setData(result);
        }
        fetchData();
    }, []);

    return (
        <div>
            <header style={{backgroundColor: "#000000", height: "150px!important", width: "100%"}}>
                <div className="containHead">
                    <div className="logo" style={{  padding: '5px' }}>
                        {/*<img style={{ width: "30px", padding: "5px" }}  src="https://www.comunetrecase.it/c062037/images/icone/RCampania-RA-1.png" alt="Regione Campania Logo"/>*/}
                        <span className="logo-label">🤓 Be smart, rebalance your stocks portfolio</span>
                    </div>
                </div>
            </header>
            <div>
                <h2  className="more-bottom">Welcome to AlphaChat📈</h2>
            </div>
            <div style={{ margin: '0 10px' }}>
                <table style={{ width: '100%' }}>
                    <thead>
                    <tr>
                        <th>Prompt</th>
                        <th>Response</th>
                        <th>Score</th>
                        <th>Feedback</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.prompt}</td>
                            <td>{item.response}</td>
                            <td>
                                <div className="star-selector">
                                    {[1, 2, 3, 4, 5].map((num) => (
                                        <label key={num} htmlFor={`${item.id}-star${num}`}
                                               onClick={() => setSelectedScore(num)}>
                                            <i className={num <= selectedScore ? "fas fa-star" : "far fa-star"}></i>
                                        </label>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <textarea
                                        className="inputClass"
                                        id={`${item.id}-feedback`}
                                        name={`${item.id}-feedback`}
                                        placeholder="Enter your feedback"
                                        style={{ width: '100%' }}
                                        rows={4} // specify number of rows
                                    />

                                    <button
                                        className="wizard-button feedback-send"
                                        style={{ display: 'block', width: '100%', marginTop: '10px' }}
                                    >
                                        Submit Feedback
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>


                </table>
            </div>

        </div>
    );
}

export default Employee;
