import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7f50']; // Custom colors for each intention
const chartWidthPercentage = 40;
const containerWidth = window.innerWidth * (chartWidthPercentage / 100);

const IntentionsPieChart = () => {
    const [intentionsData, setIntentionsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const selectedProjectStorage = localStorage.getItem('selectedProject');
            if (selectedProjectStorage) {
                const obj = JSON.parse(selectedProjectStorage);
                const loopResponse = await fetch(`https://pako-ai.herokuapp.com/loop?projectId=${obj.id}`);
                const loopData = await loopResponse.json();
                const intentionsWithValues = loopData.filter((entry) => entry.intention);

                // Extract the unique intentions and their counts
                const intentionsCountMap = intentionsWithValues.reduce((acc, entry) => {
                    acc[entry.intention] = (acc[entry.intention] || 0) + 1;
                    return acc;
                }, {});

                // Format the intentions data for the PieChart
                const formattedIntentionsData = Object.keys(intentionsCountMap).map((intention) => ({
                    name: intention,
                    value: intentionsCountMap[intention],
                }));

                setIntentionsData(formattedIntentionsData);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{
            width: '99%',
            margin: '0 auto',
            marginTop: '10px',
            marginRight: '20px',
            border: '1px solid #dedede',
            borderRadius: '10px',
            padding: '10px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
        }}>
            <h4><strong>Top Intentions</strong></h4>
            <PieChart width={containerWidth} height={300}>
                <Pie
                    data={intentionsData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    labelLine={false}
                >
                    {intentionsData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </div>
    );
};

export default IntentionsPieChart;
