import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';

function ModelSelector({ projectId }) {
    const [selectedModel, setSelectedModel] = useState("");
    const [models, setModels] = useState([]);
    const [temperature, setTemperature] = useState("1"); // Default value set to "1"
    const [maxTokens, setMaxTokens] = useState("");

    useEffect(() => {
        fetch("https://pako-ai.herokuapp.com/getAllModels")
            .then((response) => response.json())
            .then((data) => {
                const modelNames = data.map((model) => model.name);
                setModels(modelNames);
            });
    }, []);

    useEffect(() => {
        if (projectId) {
            fetch(`https://pako-ai.herokuapp.com/getAiParamsByProjectId/${projectId}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.name) {
                        setSelectedModel(data.name);
                        setTemperature(data.temperature ? data.temperature.toString() : "1");
                        setMaxTokens(data.max_tokens || "512"); // Default to "512" if no value from the backend
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [projectId]);

    const handleModelChange = (model) => {
        setSelectedModel(model);
    };

    useEffect(() => {
        if (selectedModel) {
            const data = {
                name: selectedModel,
                projectId,
                temperature: temperature ? parseFloat(temperature) : null,
                max_tokens: maxTokens ? parseInt(maxTokens) : null,
            };
            fetch("https://pako-ai.herokuapp.com/changeAiParams", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [selectedModel, projectId, temperature, maxTokens]);

    return (
        <div>
            <Form.Group>
                <Form.Label>Temperature:</Form.Label>

                <DropdownButton id="temperature-dropdown"  title={temperature || 'Select Temperature'} variant="light">
                    <Dropdown.Item onClick={() => setTemperature("0")}>0</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.1")}>0.1</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.2")}>0.2</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.3")}>0.3</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.4")}>0.4</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.5")}>0.5</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.6")}>0.6</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.7")}>0.7</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.8")}>0.8</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("0.9")}>0.9</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTemperature("1")}>1</Dropdown.Item>
                </DropdownButton>
            </Form.Group>
            <Form.Group>
                <Form.Label>Tokens:</Form.Label>

                <DropdownButton id="max-tokens-dropdown" title={maxTokens || 'Select Max Tokens'} variant="light">
                    <Dropdown.Item onClick={() => setMaxTokens("256")}>256</Dropdown.Item>
                    <Dropdown.Item onClick={() => setMaxTokens("512")}>512</Dropdown.Item>
                    <Dropdown.Item onClick={() => setMaxTokens("1024")}>1024</Dropdown.Item>
                    <Dropdown.Item onClick={() => setMaxTokens("2048")}>2048</Dropdown.Item>
                </DropdownButton>
            </Form.Group>

            <Form.Label>Model:</Form.Label>

            <DropdownButton id="model-dropdown-button" title={selectedModel || 'Select Model'} variant="light">
                {models.map((model, index) => (
                    <Dropdown.Item key={index} onClick={() => handleModelChange(model)}>
                        {model}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
}

export default ModelSelector;
