import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import "./UseCases.css";

const UseCases = () => {
    const [projectId, setProjectId] = useState(null);
    const [useCases, setUseCases] = useState([]);
    const [useCaseOptions] = useState([{ value: 'ALL', label: 'ALL' }]);
    const [communicationOptions] = useState([
        { value: 'ALL', label: 'ALL' },
        { value: 'Call', label: 'Call' },
        { value: 'Whatsapp', label: 'WhatsApp' },
    ]);
    const [selectedUseCaseValue, setSelectedUseCaseValue] = useState('');
    const [selectedCommunicationValue, setSelectedCommunicationValue] = useState('');
    const [filteredUseCases, setFilteredUseCases] = useState(useCases);

    useEffect(() => {
        // Filter the useCases based on the selectedUseCaseValue and selectedCommunicationValue
        const filteredData = useCases.filter((useCase) => {
            const useCaseMatch =
                selectedUseCaseValue === 'ALL' || useCase.useCase === selectedUseCaseValue;
            const communicationMatch =
                selectedCommunicationValue === 'ALL' || useCase.communicationOption === selectedCommunicationValue;
            return useCaseMatch && communicationMatch;
        });

        // Set the filtered useCases to update the table
        setFilteredUseCases(filteredData);
    }, [selectedUseCaseValue, selectedCommunicationValue, useCases]);

    const fetchUseCases = useCallback(async () => {
        try {
            const response = await axios.get(`https://pako-ai.herokuapp.com/getIntents?projectId=${projectId}`);
            setUseCases(response.data.length > 0 ? response.data : [{ intentName: '', words: '', expectedResponse: '', finishConversation: false }]);
        } catch (error) {
            console.error('Error fetching use cases:', error);
        }
    }, [projectId]); // Dependency added her

    useEffect(() => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const selectedProject = JSON.parse(selectedProjectStorage);
            setProjectId(selectedProject.id);
        }
    }, []);

    useEffect(() => {
        if (projectId) {
            fetchUseCases();
        }
    }, [projectId, fetchUseCases]);

    useEffect(() => {
        setSelectedUseCaseValue(useCaseOptions[0].value); // Assuming the first option is the default
        setSelectedCommunicationValue(communicationOptions[0].value); // Assuming the first option is the default
    }, [useCaseOptions, communicationOptions]);

    const handleAddRow = () => {
        setUseCases([...useCases, { intentName: '', words: '', expectedResponse: '', finishConversation: false }]);
    };

    const handleDeleteIntent = (intentId) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ intent_id: intentId }),
        };

        // Fetch the API endpoint for deleting the intent
        fetch('https://pako-ai.herokuapp.com/deleteIntent', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Handle the response data as needed

                // If the API call succeeds, remove the intent from the state
                if (data.message === 'Intent deleted successfully!') {
                    setUseCases((prevUseCases) =>
                        prevUseCases.filter((intent) => intent.intent_id !== intentId)
                    );
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleInputChange = (index, field, value) => {
        const updatedUseCases = [...useCases];
        updatedUseCases[index][field] = value;
        setUseCases(updatedUseCases);
    };

    const handleSaveIntents = async () => {
        try {
            // Transform the intents array to include 'communication' property in each row
            const selectedUseCaseOptions = useCaseOptions.filter(option => option.value === selectedUseCaseValue);
            const selectedCommunicationOptions = communicationOptions.filter(option => option.value === selectedCommunicationValue)
            const updatedIntents = useCases.map((useCase) => ({
                ...useCase,
                communication: useCase.communicationMethod, // Assuming 'communicationMethod' is the property in 'useCase' for the communication option
                useCase: selectedUseCaseOptions[0]?.value || null, // Use the first option if selected is empty, set to null if none selected
                communicationOption: selectedCommunicationOptions[0]?.value || null, // Use the first option if selected is empty, set to null if none selected
            }));

            const dataToSave = {
                projectId,
                intents: updatedIntents,
            };


            await axios.post('https://pako-ai.herokuapp.com/saveNewIntents', dataToSave);
            console.log('Intents saved successfully!');
        } catch (error) {
            console.error('Error saving intents:', error);
        }
    };


    return (
        <div>
            <label htmlFor="communicationDropdown">Select the communication option:</label>

            {/* The new dropdown for Call or WhatsApp */}
            <DropdownButton
                id="communicationDropdown"
                title={selectedCommunicationValue || 'Select Communication'}
                variant="light"
            >
                {communicationOptions.map((option, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => setSelectedCommunicationValue(option.value)}
                    >
                        {option.label}
                    </Dropdown.Item>
                ))}
            </DropdownButton>

            <label htmlFor="useCase">Select the use case:</label>

            <DropdownButton
                id="useCaseDropdown"
                title={selectedUseCaseValue || 'Select Use Case'}
                variant="light"
            >
                {useCaseOptions.map((option, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => setSelectedUseCaseValue(option.value)}
                    >
                        {option.label}
                    </Dropdown.Item>
                ))}
            </DropdownButton>

            <div>
                <table>
                <thead>
                <tr>
                    <th>Intent name</th>
                    <th>List of words</th>
                    <th>Expected response</th>
                    <th>Finish call</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {filteredUseCases.map((useCase, index) => (
                    <tr key={index}>
                        <td>
                            <input
                                type="hidden" // Hidden input for intent_id
                                value={useCase.intent_id || ''} // If intent_id is available, set the value
                            />
                            <input
                                type="text"
                                value={useCase.intentName}
                                onChange={(e) => handleInputChange(index, 'intentName', e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={useCase.words}
                                onChange={(e) => handleInputChange(index, 'words', e.target.value)}
                            />
                        </td>
                        <td>
                             <textarea
                                 rows="2" // Set the textarea to display two visible lines
                                 value={useCase.expectedResponse}
                                 onChange={(e) => handleInputChange(index, 'expectedResponse', e.target.value)}
                             />
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                checked={useCase.finishConversation}
                                onChange={(e) => handleInputChange(index, 'finishConversation', e.target.checked)}
                            />
                        </td>
                        <td>
                            <button class="delete-intent"
                                onClick={() => handleDeleteIntent(useCase.intent_id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
                <div class="group-buttons">
                    <button class="add-row"
                        onClick={handleAddRow}>Add Row</button>
                    <button class="save-intents"
                        onClick={handleSaveIntents}>Save New Intents</button>
                </div>
            </div>
        </div>
    );
};

export default UseCases;
