import React, { Component } from 'react';
import "./CallsTranscript.css";

class CallsTranscript extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            users: [], // Add users state for the user-list
            currentUserId: null,
            searchQuery: '', // Add searchQuery state for filtering users
        };
    }

    componentDidMount() {
        this.fetchCallTranscripts();
    }

    fetchCallTranscripts = () => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        const projectId = selectedProjectStorage ? JSON.parse(selectedProjectStorage).id : null;
        const messagesUrl = `https://pako-ai.herokuapp.com/messages?projectId=${projectId}`;

        fetch(messagesUrl)
            .then(response => response.json())
            .then(data => {
                const callTranscripts = data.filter(message => message.app_name === "Call");
                this.setState({ messages: callTranscripts });

                // Assuming the user information is present in the messages data.
                // Group the users by their id to get unique users
                const uniqueUsers = callTranscripts.reduce((acc, cur) => {
                    const existingUser = acc.find(user => user.id === cur.sender);
                    if (existingUser) {
                        return acc;
                    }
                    return [...acc, { id: cur.sender, name: cur.sender }];
                }, []);

                this.setState({ users: uniqueUsers });
            })
            .catch(error => {
                console.error('Error fetching call transcripts:', error);
            });
    };

    handleSearch = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    // Add the selectUser function to handle user selection
    selectUser = (userId) => {
        this.setState({ currentUserId: userId });
    };

    renderUserList() {
        const { users, currentUserId, searchQuery } = this.state;

        // Filter users based on search query
        const filteredUsers = users.filter(user =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase()) && user.name !== 'Call'
        );

        if (filteredUsers.length > 0) {
            // Call selectUser with last user in the list if currentUserId is not set
            if (!currentUserId) {
                this.selectUser(filteredUsers[filteredUsers.length - 1].id);
            }

            return (
                <div className="user-list">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search user..."
                        value={searchQuery}
                        onChange={this.handleSearch}
                    />
                    {filteredUsers.map(user => {
                        const isCurrentUser = currentUserId === user.id;

                        return (
                            <div
                                key={user.id}
                                className={`user-item ${isCurrentUser ? 'selected' : ''}`}
                                onClick={() => {
                                    if (!isCurrentUser) {
                                        this.selectUser(user.id);
                                    }
                                }}
                            >
                                {user.name}
                            </div>
                        );
                    })}
                </div>
            );
        }

        // Render the search input even when there are no results
        return (
            <div className="user-list">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search user..."
                    value={searchQuery}
                    onChange={this.handleSearch}
                />
                <div>No users found</div>
            </div>
        );
    }

    renderMessages() {
        const { messages } = this.state;
        return (
            <div className="messages-container">
                {messages.map((message) => (
                    <div key={message.id} className={`message-item ${message.sender === 'Call' ? 'sent' : 'received'}`}>
                        <div className="message-sender">{message.sender}</div>
                        <div className="message-content">{message.message}</div>
                        <div className="message-timestamp">{message.date}</div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="chat-container">
                {this.renderUserList()}
                <div className="chat-interface">
                    <div className="chat-header">Call Transcripts</div>
                    {this.renderMessages()}
                </div>
            </div>
        );
    }
}

export default CallsTranscript;
