import React, { useState, useEffect } from 'react';
import "./Settings.css";

function Settings() {
    const [projectId, setProjectId] = useState('');

    // retrieve projectId from local storage on component mount
    useEffect(() => {
        const selectedProjectStorage = localStorage.getItem('selectedProject');
        if (selectedProjectStorage) {
            const obj = JSON.parse(selectedProjectStorage);
            setProjectId(obj.id);
        }
    }, []);

    const handleCopyLink = () => {
        const linkInput = document.getElementById('linkInput');
        linkInput.select();
        linkInput.setSelectionRange(0, 99999); // for mobile devices
        document.execCommand('copy');
        alert('Link copied to clipboard!');
    };


    return (
        <div className="main-container">
            {/*<h2 className="more-bottom">Settings</h2>*/}
            <h2 className="margin-top-20">Do you want to integrate in Whatsapp?</h2>
            <br/>
            <p>First you need to make a new account on <a href="https://www.twilio.com/">Twilio</a> and need to have a business number on Whatsapp.</p>

            <p>Copy and paste this link:</p>
            <input type="text" id="linkInput" value={`https://pako-ai.herokuapp.com/whatsapp/receive?projectId=${projectId}`} readOnly style={{ width: '500px' }} />
            <button onClick={handleCopyLink}>Copy Link</button>
            <br/>
            <br/>
            <p>Go to Senders/ Whatsapp senders and select your phone number. Then paste the link in Webook URL.</p>
            <img width="800px" alt="twilio" src="/images/twilioUrl.png"/>
            <br/>            <br/>
            <p>Save and you are ready to go.</p>
        </div>
    );
}

export default Settings;
