import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faChartLine, faLightbulb,  faRobot, faPhone, faHandshake, faComment, faDatabase, faFlask, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import './Sidebar.css';

function Sidebar() {
    const [isAdmin, setAdmin] = useState(false);
    const [selectedProject, setSelectedProject] = useState([]);
    const currentPath = window.location.pathname;


    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await fetch('https://pako-ai.herokuapp.com/profile', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                });
                const data = await response.json();
                localStorage.setItem('user', JSON.stringify({ email: data.email }));
                setAdmin(data.role === 'admin');
            } catch (error) {
                console.log(error); // or show an error message to the user
            }
        };

        checkToken();
    }, []);

    useEffect(() => {
        const checkSelectedProject = () => {
            const selectedProjectStorage = localStorage.getItem('selectedProject');
            if (selectedProjectStorage) {
                const obj = JSON.parse(selectedProjectStorage);
                if (obj && obj.id) {
                    setSelectedProject(obj);
                } else {
                    console.log('Invalid selected project'); // or show an error message to the user
                }
            } else {
                console.log('No selected project'); // or show an error message to the user
            }
        };

        checkSelectedProject();
    }, []);

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <img src="/talkstackAiWhite.png" alt="Chat AI Logo" style={{width: '175px', paddingTop: '0px', marginLeft: '10px'}} />
            </div>
            {selectedProject && selectedProject.id && (
                <li className={`menu-item ${currentPath === '/dashboard/analytics' || currentPath === '/' ? 'active' : ''}`}>
                    <a href="/dashboard/analytics" className="menu-link">
                        <FontAwesomeIcon icon={faChartLine} className="menu-icon" />
                        <span className="menu-text">Analytics</span>
                    </a>
                </li>
            )}
            <ul className="menu">
                {/*{selectedProject && selectedProject.id && (*/}

                {/*    <li className={`menu-item ${currentPath === '/dashboard/whatsapp' ? 'active' : ''} ${selectedProject && selectedProject.id === 'cdb2a897-d094-463c-a7a0-635aad391103' ? 'disabled' : ''}`}>*/}
                {/*        <a href="/dashboard/whatsapp" className="menu-link">*/}
                {/*            <FontAwesomeIcon icon={faWhatsapp} className="menu-icon" />*/}
                {/*            <span className="menu-text">Whatsapp Chat</span>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*)}*/}

                {selectedProject && selectedProject.id && (

                        <li className={`menu-item ${currentPath === '/dashboard/chat' ? 'active' : ''} ${selectedProject && selectedProject.id === '59679d75-ab9c-4c60-8323-a4dfcc87daba' ? 'disabled' : ''}`}>
                        <a href="/dashboard/chat" className="menu-link">
                            <FontAwesomeIcon icon={faComment} className="menu-icon" />
                            <span className="menu-text">Web Chat</span>
                        </a>
                    </li>
                )}

                {selectedProject && selectedProject.id && selectedProject.id !== 'cdb2a897-d094-463c-a7a0-635aad391103' && (
                    <li className={`menu-item ${currentPath === '/dashboard/phone' ? 'active' : ''} ${selectedProject && selectedProject.id === 'cdb2a897-d094-463c-a7a0-635aad391103' ? 'disabled' : ''}`}>
                        <a href="/dashboard/call" className="menu-link">
                            <FontAwesomeIcon icon={faPhone} className="menu-icon" />
                            <span className="menu-text">Phone Calls</span>
                        </a>
                    </li>
                )}

                {/*{selectedProject && selectedProject.id === '59679d75-ab9c-4c60-8323-a4dfcc87daba' && (*/}
                {/*    <li className={`menu-item ${currentPath === '/dashboard/reminders' ? 'active' : ''}`}>*/}
                {/*        <a href="/dashboard/reminders" className="menu-link">*/}
                {/*            <FontAwesomeIcon icon={faBell} className="menu-icon" />*/}
                {/*            <span className="menu-text">Reminders</span>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*)}*/}



                {/*{selectedProject && selectedProject.id && (*/}

                {/*    <li className={`menu-item ${currentPath === '/dashboard/queries' ? 'active' : ''}`}>*/}
                {/*    <a href="/dashboard/queries" className="menu-link">*/}
                {/*        <FontAwesomeIcon icon={faSearch} className="menu-icon" />*/}
                {/*        <span className="menu-text">All queries</span>*/}
                {/*    </a>*/}
                {/*</li>*/}
                {/*)}*/}
                {selectedProject && selectedProject.id && (
                    <li className={`menu-item ${currentPath === '/dashboard/upload' ? 'active' : ''}`}>
                    <a href="/dashboard/upload" className="menu-link">
                        <FontAwesomeIcon icon={faDatabase} className="menu-icon" />
                        <span className="menu-text">Upload Context</span>
                    </a>
                </li>
                    )}

                {selectedProject && selectedProject.id && (
                    <li className={`menu-item ${currentPath === '/dashboard/intentions' ? 'active' : ''}`}>
                        <a href="/dashboard/intentions" className="menu-link">
                            <FontAwesomeIcon icon={faLightbulb} className="menu-icon" />
                            <span className="menu-text">Intentions</span>
                        </a>
                    </li>
                )}

                {selectedProject && selectedProject.id && (
                    <li
                        className={`menu-item ${currentPath === '/dashboard/chat' ? 'active' : ''} ${
                            selectedProject && selectedProject.id === '59679d75-ab9c-4c60-8323-a4dfcc87daba' ? 'disabled' : ''
                        }`}
                    >
                    <a href="/dashboard" className="menu-link">
                        <FontAwesomeIcon icon={farStar} className="menu-icon" />
                        <span className="menu-text">Fine Tuning</span>
                    </a>
                </li>
                    )}

                {selectedProject && selectedProject.id && (
                    <li
                        className={`menu-item ${currentPath === '/dashboard/chat' ? 'active' : ''} ${
                            selectedProject && selectedProject.id === '59679d75-ab9c-4c60-8323-a4dfcc87daba' ? 'disabled' : ''
                        }`}
                    >
                        <a href="/dashboard" className="menu-link">
                            <FontAwesomeIcon icon={faFlask} className="menu-icon" />
                            <span className="menu-text">Prompt Testing</span>
                        </a>
                    </li>
                )}


                {selectedProject && selectedProject.id && (
                    <li className={`menu-item ${currentPath === '/settings' ? 'active' : ''} ${selectedProject && selectedProject.id === 'cdb2a897-d094-463c-a7a0-635aad391103' ? 'disabled' : ''}`}>
                    <a href="/dashboard/settings" className="menu-link">
                        <FontAwesomeIcon icon={faCog} className="menu-icon" />
                        <span className="menu-text">Settings</span>
                    </a>
                </li>
                )}
                {isAdmin && (
                    <li className={`menu-item ${currentPath === '/dashboard/admin' ? 'active' : ''} ${selectedProject && selectedProject.id === 'cdb2a897-d094-463c-a7a0-635aad391103' ? 'disabled' : ''}`}>
                        <a href="/dashboard/admin" className="menu-link">
                            <FontAwesomeIcon icon={faUserShield} className="menu-icon" />
                            <span className="menu-text">Admin</span>
                        </a>
                    </li>
                )}
                {selectedProject && selectedProject.id && (
                    <li
                        className={`menu-item ${currentPath === '/dashboard/crm' ? 'active' : ''} ${
                            selectedProject && selectedProject.id === '59679d75-ab9c-4c60-8323-a4dfcc87daba' ? 'disabled' : ''
                        }`}
                    >    <a href="/dashboard" className="menu-link">
                            <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                            <span className="menu-text">CRM</span>
                        </a>
                    </li>
                )}

                {selectedProject && selectedProject.id && (
                    <li
                        className={`menu-item ${currentPath === '/dashboard/studio' ? 'active' : ''} ${
                            selectedProject && selectedProject.id === '59679d75-ab9c-4c60-8323-a4dfcc87daba' ? 'disabled' : ''
                        }`}
                    >                <a href="/dashboard" className="menu-link">
                            <FontAwesomeIcon icon={faRobot} className="menu-icon" />
                            <span className="menu-text">GPT Bot Studio</span>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default Sidebar;
