import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./../App.css";
import "./Queries.css";

// ALL for admin query

function Queries() {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    useEffect(() => {
        async function fetchData() {
            const selectedProjectStorage = localStorage.getItem('selectedProject');
            if (selectedProjectStorage) {
                const obj = JSON.parse(selectedProjectStorage);

                const loopResponse = await fetch(`https://pako-ai.herokuapp.com/loop?projectId=${obj.id}`);
                const feedbackResponse = await fetch(`https://pako-ai.herokuapp.com/feedback?projectId=${obj.id}`);
                const feedbackData = await feedbackResponse.json();
                const loopData = await loopResponse.json();

                const mergedData = loopData.map((item) => {
                    const feedbackItem = feedbackData.find((feedback) => {
                        return feedback.feedbackId === item._id;
                    });
                    const rating = feedbackItem ? feedbackItem.rating : 'N.A.';
                    return { ...item, rating };
                });
                mergedData.sort((a, b) => new Date(b.date) - new Date(a.date));
                setData(mergedData);
            }
        }
        fetchData();
    }, []);

    const handleRowClick = (itemId) => {
        console.log('Clicked', itemId);
        setSelectedItemId(itemId);
    };

    useEffect(() => {
        if (selectedItemId) {
            history.push(`/dashboard/queries/${selectedItemId}`);
        }
    }, [selectedItemId, history]);

    const filteredData = data.filter(item => item.date);

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        filteredData.sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    };

    return (
        <div className="border-rounded">
            <div className="table-container">
                <table style={{ width: '100%' }}>
                    <thead>
                    <tr>
                        <th onClick={() => sortData('date')}>Date</th>
                        <th>Prompt</th>
                        <th>Response</th>
                        <th>Feedback</th>
                        <th>Intention</th>
                        <th style={{ width: '5%' }}>Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.map((item) => (
                        <tr key={item.id} onClick={() => handleRowClick(item.id)}>
                            <td>{new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                            <td>{item.prompt.substring(0, 50)}...</td>
                            <td>{item.response.substring(0, 50)}...</td>
                            <td>N.A.</td>
                            <td>{item.intention ? item.intention.substring(0, 50) : 'N.A.'}</td>
                            <td style={{ fontSize: (item.rating === '😔' || item.rating === '😐' || item.rating === '😊') ? '1.5em' : 'inherit'}}>
                                {item.rating === 'sad' ? '😔' : item.rating === 'medium' ? '😐' : item.rating === 'happy' ? '😊' : item.rating}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Queries;
