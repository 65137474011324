import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Employee from './Employee';
import Dashboard from './Dashboard';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';


import NotFound from './NotFound';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/employee" component={Employee} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/login" component={LoginPage} />
                <Route path="/register" component={RegisterPage} />


                <Route component={NotFound} />
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
